import React, { useState, useEffect } from 'react'
import GameModal from 'pageComponents/GameModal'
import { getDatabase, ref, set } from 'firebase/database'
import ColorCard from 'components/ColorCard/ColorCard'
import Button3d from 'components/Button3d'
import styles from './dauberModal.module.scss'
import { images } from 'assets'


const DauberModal = ({
  userid,
  onClick,
  dauberColor
}) => {
  const db = getDatabase();

  const [dauberColor_, setDauberColor_] = useState(dauberColor);

  const selectColor = (color) => {
    set(ref(db, "/users/" + userid + "/settings/dauber/"), color);
    setDauberColor_(color)
  }

  const modalContent = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <p className={styles.title}>Dauber Color</p>
        <p className={styles.subtitle}>you can chooes the color of your bingo Dauber</p>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <ColorCard color={'#6764cb'} dauberColor={dauberColor_} onClick={selectColor} />
            <ColorCard color={'#A55AEA'} dauberColor={dauberColor_} onClick={selectColor} />
            <ColorCard color={'#FC86FC'} dauberColor={dauberColor_} onClick={selectColor} />
            <ColorCard color={'#FFB3B3'} dauberColor={dauberColor_} onClick={selectColor} />
            <ColorCard color={'#FF3334'} dauberColor={dauberColor_} onClick={selectColor} />
            <ColorCard color={'#FFBD33'} dauberColor={dauberColor_} onClick={selectColor} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
            <img src={images.dauber_bottle} className={styles.bottle} />
            <div style={{ backgroundColor: dauberColor_, maskImage: `url(${images.dauber_tip})`, position: 'absolute', width: '100%', height: '100%', maskSize: 'contain', maskRepeat: 'no-repeat', maskPosition: 'bottom' }}></div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <ColorCard color={'#FF5733'} dauberColor={dauberColor_} onClick={selectColor} />
            <ColorCard color={'#FEE74C'} dauberColor={dauberColor_} onClick={selectColor} />
            <ColorCard color={'#D9E447'} dauberColor={dauberColor_} onClick={selectColor} />
            <ColorCard color={'#9DEC5B'} dauberColor={dauberColor_} onClick={selectColor} />
            <ColorCard color={'#47E5C2'} dauberColor={dauberColor_} onClick={selectColor} />
            <ColorCard color={'#32A2A6'} dauberColor={dauberColor_} onClick={selectColor} />
          </div>
        </div>


        <div style={{ width: '50%', marginTop: 16, alignSelf: 'center', display: 'flex' }}>

          <Button3d className={`btn-red`} label={'Close'} onClick={() => onClick()} ></Button3d>
        </div >
      </div>
    )
  }


  return <GameModal content={modalContent()} ></GameModal>
}

export default DauberModal
