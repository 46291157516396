import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Button from 'components/Button'
import { actions } from 'slices/app.slice'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { images } from 'theme'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import {
  getDatabase,
  ref,
  onValue,
  onChildChanged,
  query,
  get,
  child,
  set,
  update,
  onDisconnect,
} from 'firebase/database'
import {
  getFirestore,
  collection,
  query as fsquery,
  where,
  onSnapshot,
  updateDoc,
  doc,
  and,
  getDoc,
  addDoc,
} from 'firebase/firestore'
import styles from './game.module.scss'
import CardButton from 'pageComponents/CardButton'
import ImageButton from 'pageComponents/ImageButton/ImageButton'
import 'animate.css'
import ShowAll from 'pageComponents/ShowAll'
import WinPattern from 'pageComponents/WinPattern'
import NoWin from 'pageComponents/NoWin'
import ImageModal from 'pageComponents/ImageModal'
import Alert from 'pageComponents/Alert'
import VideoModal from 'pageComponents/VideoModal'
import TdButton from 'pageComponents/3dButton/TdButton'
import Chat from 'pageComponents/Chat'
import okaudio from '../../assets/sounds/ok.wav'
import noaudio from '../../assets/sounds/no.wav'
import AgoraRTC from 'agora-rtc-sdk-ng'
import {
  getStorage,
  ref as sRef,
  getDownloadURL,
  uploadBytes,
} from 'firebase/storage'
import SoundSettings from 'pageComponents/SoundSettings'
import LCardButton from 'pageComponents/LCardButton'
import LImageModal from 'pageComponents/LImageModal'
import DauberModal from 'pageComponents/DauberModal'
var CryptoJS = require('crypto-js')
let agoraEngine = new AgoraRTC.createClient({ mode: 'rtc', codec: 'vp9' })
const Game = () => {
  const { me } = useSelector((state) => state.app)
  const history = useHistory()
  const db = getDatabase()
  const storage = getStorage()
  const fsdb = getFirestore()
  const { search } = useLocation()
  const [width, setWidth] = useState(0)
  // const [windowHeight, setWindowHeight] = useState(0)
  const [backcolor, setBackcolor] = useState('green')
  const [winPattern, setWinPattern] = useState([])
  const [message, setMessage] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const roboCallerActive = useRef(false)
  const roboCallerAccent = useRef('male-ron-american-accent')
  const userSettings = useRef({
    dauber: '#6764cb',
    muteall: false,
    muteinvalidchecks: false,
    muterobo: false,
    mutevalidchecks: false,
    mutewinsounds: false,
  })

  const ref_ = useRef(null)
  const currentgamenoint = useRef(0)
  const [tiles, setTiles] = useState([])
  const [cardno, setCardNo] = useState(0)
  const [callBingoDisable, setCallBingoDisable] = useState(false)
  const [sessionNo, setsessionNo] = useState(null)
  const [inputError, setInputError] = useState('')
  const [winGifs, setWiGifs] = useState([])
  const [winPatternModalVisible, setWinPatternModalVisible] = useState(false)
  const [imageModalVisible, setImageModalVisible] = useState(false)
  const [LImageModalVisible, setLImageModalVisible] = useState(false)
  const [image, setImage] = useState('')
  const [tilesForModal, setTilesForModal] = useState([])
  const [noWin, setNoWin] = useState(false)
  const [imageAutoClose, setImageAutoClose] = useState(false)
  const [videoModalVisible, setVideoModalVisible] = useState(false)
  const [videourl, setVideourl] = useState('')

  const [currentNo, setCurrentNo] = useState(null)
  const [numsDrawn, setNumsDrawn] = useState(0)
  const [lastNo, setLastNo] = useState(null)
  const [gameNo, setGameNo] = useState(0)
  const [currentGameName, setCurrentGameName] = useState('')
  const [drawnNumbers, setDrawnNumbers] = useState(null)

  const [showAll, setShowAll] = useState(false)
  const [showSoundSettings, setShowSoundSettings] = useState(false)
  const [isChatShow, setIsChatShow] = useState(false)
  const [showDauberModal, setShowDauberModal] = useState(false)
  const loteriaImages = useRef(null)
  const [underline, setUnderline] = useState([
    false,
    false,
    false,
    false,
    false,
  ])
  const gamecolorshex = [
    // "#1f487b",
    '#005874', //8
    '#FB7F0C', //10
    '#153914', //9
    '#998105', //5
    '#DB6481', //game 4
    '#646668', //game 2
    '#1E7400', //6
    '#351808', //game 3
    '#650214', //7
    '#450265', //game 1
  ]

  //Game background images
  const gameBackground = [
    images.game8Background,
    images.game10Background,
    images.game9Background,
    images.game5Background,
    images.game4Background,
    images.game2Background,
    images.game6Background,
    images.game3Background,
    images.game7Background,
    images.game1Background,
  ]
  //Game Ball Images
  const gameBallImages = [
    images.blueBall,
    images.redBall,
    images.greyBall,
    images.greenBall,
    images.yellowBall,
  ]
  const gameBallImagesL = [
    images.one,
    images.two,
    images.three,
    images.four,
    images.five,
  ]
  const wordBingo = ['B', 'I', 'N', 'G', 'O']
  //Ball Image Selector
  function getGameBallImage(number) {
    if (number >= 1 && number <= 15) {
      return gameBallImages[0]
    } else if (number >= 16 && number <= 30) {
      return gameBallImages[1]
    } else if (number >= 31 && number <= 45) {
      return gameBallImages[2]
    } else if (number >= 46 && number <= 60) {
      return gameBallImages[3]
    } else if (number >= 61 && number <= 75) {
      return gameBallImages[4]
    } else {
      // Handle numbers outside the defined ranges
      return null // You can return a default value or handle this case as needed
    }
  }
  const getLoteriaImages = async () => {
    const dbRef = ref(db)
    console.log(getGroupID())
    get(child(dbRef, `groups/${getGroupID()}/loteria`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          let ltt = []
          let ltp = []

          snapshot.val().thumbnails.forEach(function (item) {
            ltt.push(item)
          })
          snapshot.val().popup.forEach(function (item) {
            ltp.push(item)
          })
          loteriaImages.current = { thumbs: ltt, popup: ltp }
        } else {
          console.log('No loteria data available')
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const roboCallerVoice = (path) => {
    if (userSettings.current.muterobo) {
      if (roboCallerActive.current) {
        const pathReference = sRef(storage, path)
        getDownloadURL(pathReference)
          .then((url) => {
            console.log(url)
            playSound(url) //Mute RoboHost caller
          })
          .catch((error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/object-not-found':
                // File doesn't exist
                break
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break
              case 'storage/canceled':
                // User canceled the upload
                break

              // ...

              case 'storage/unknown':
                // Unknown error occurred, inspect the server response
                break
            }
          })
      }
    }
  }
  const Decrypt = (word, key = 'share') => {
    let decData = CryptoJS.enc.Base64.parse(word).toString(CryptoJS.enc.Utf8)
    let bytes = CryptoJS.AES.decrypt(decData, key).toString(CryptoJS.enc.Utf8)
    return JSON.parse(bytes)
  }

  useEffect(() => {
    // alert(me.id)

    // setWindowHeight(windowRef.current.clientHeight)
    // setWidth(windowRef.current.clientHeight / 16 * 8 / 5)

    console.log(me.id)
    let locationRef = getLocationRef()
    getWinGifs()
    getLoteriaImages()
    const currentGameRef = ref(db, locationRef + '/currentgame')

    //------------------------game no change event------------------------//
    const currentGameNoRef = ref(
      db,
      locationRef + '/currentgame/currentgameint',
    )

    onChildChanged(currentGameNoRef, (data) => {
      if (data.val() > 0) {
        // setAllowJoin(true);

        // if (!route.params.allowJoin) {
        //   setChatModalVisible(false);
        // }

        getNewCard(sessionNo)

        roboCallerVoice(
          roboCallerAccent.current + '/others/g' + data.val() + '.mp3',
        )
      }
    })
    //------------------------end game no change event------------------------//

    //------------------------new winner change event------------------------//

    const winnersRef = ref(db, locationRef + '/currentgame/lastwinner')

    onChildChanged(winnersRef, (data) => {
      // console.log("trigered hereeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee")

      if (data.val() != '') {
        setWinPattern(data.val().winpattern)
        var tempdata = []
        for (var i = 0; i < data.val().pattern.length; i++) {
          tempdata.push({
            selected: data.val().pattern[i].iswin,
            no: data.val().pattern[i].num,
          })
        }

        setTilesForModal(tempdata) //winpattern display
        setWinPatternModalVisible(true)
        setImageAutoClose(true)
        setTimeout(hideWinPatternShowGif.bind(null, data.val().gifm), 5000)

        setTimeout(
          () =>
            roboCallerVoice(
              roboCallerAccent.current +
                '/others/w' +
                getRndInteger(1, 10) +
                '.mp3',
            ),
          2000,
        )
      }
    })
    //------------------------end of new winner change event------------------------//

    //------------------------no winner change event------------------------//

    const winTryRef = ref(db, locationRef + '/currentgame/lastwintry')
    onChildChanged(winTryRef, (data) => {
      if (
        data.val().isfromapp == false &&
        data.val().ismanual == false &&
        data.val().isstaff == false &&
        data.val().user.userid == me.id
      ) {
        setNoWin(true)
      }
    })
    //------------------------end of no winner change event------------------------//

    onChildChanged(currentGameRef, async (data) => {
      if (data.key == 'currentnoint') {
        if (data.val() > 0) {
          animateCSS(
            '#current-no',
            getCategory() == 4 ? 'flip' : 'bounceInDown',
          )

          animateCSS('#last-no', getCategory() == 4 ? 'slideInLeft' : 'rollIn')
          if (getCategory() == 4) {
            setImage(
              loteriaImages.current?.popup.find(
                (item_) => item_.id == data.val(),
              )?.url,
            )
            console.log(loteriaImages.current)
            console.log(
              loteriaImages.current?.popup?.find(
                (item_) => item_.id == data.val(),
              )?.url,
            )
            // setImageAutoClose(true)
            setLImageModalVisible(true)
            // imageL.current = loteriaImages?.popup.find(
            //   (item_) => item_.id == data.val()
            // ).url;
          }

          // if (!muteref_.current) {
          //   if (
          //     currentgamenoint.current == 4 &&
          //     data.val() > 30 &&
          //     data.val() < 46
          //   ) {
          //   } else {
          roboCallerVoice(roboCallerAccent.current + '/' + data.val() + '.mp3')
          //   }
          // }
        }
      }

      //listen to presentation
      if (data.val().ispresentationstarted) {
        setImageAutoClose(false)
        setImage(data.val().activeslide.m)
        setImageModalVisible(true)
      } else if (data.val().ispatternvisible) {
        setImage(data.val().activepattern)
        setImageModalVisible(true)
      } else {
        setImageModalVisible(false)
      }
      //listen to video
      if (data.val().isvideoplaying) {
        // await _engine.muteAllRemoteAudioStreams(true);

        setVideourl(data.val().youtube)

        setVideoModalVisible(true)
        // setShouldPlay(true);
      } else {
        // setShouldPlay(false);
        setVideoModalVisible(false)
        // await _engine.muteAllRemoteAudioStreams(false);
      }
      if (data.val().iscustommessage) {
        setMessage({ title: '', subtitle: data.val().message })
        setShowAlert(true)
      }
      if (data.val().ishotkey) {
        setMessage({
          title: data.val().title,
          subtitle: data.val().description,
        })
        setShowAlert(true)
      }
    })

    return onValue(currentGameRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val()
        console.log(data.currentno)
        console.log(currentNo)
        // if (currentNo != data.currentno) {

        //   animateCSS('#current-no', 'bounceInDown');
        //   animateCSS('#last-no', 'rollIn');
        // }
        roboCallerAccent.current = data.voiceaccent
        roboCallerActive.current = data.isroboactive
        // cni = data.currentnoint ? data.currentnoint : 0;
        // lni = data.lastnoint;
        setCurrentNo(data.currentno)
        // setCurrentNoInt(data.currentnoint);
        setCurrentGameName(data.currentgame)
        // console.log(data.currentgame)
        setLastNo(data.lastno)
        setNumsDrawn(data.numbersdrawn)

        setDrawnNumbers(data.drawnnumbers)
        setGameNo(data.currentgameint.gameno)
        currentgamenoint.current = data.currentgameint.gameno
        setBackcolor(gamecolorshex[data.currentgameint.gameno - 1])
        setsessionNo(data.sessioninfo.sessionno)
        // setGameArray(data.gamearray);
        getOnline(data.sessioninfo.sessionno)

        // const element = document.querySelector('#current-no');
        // element.classList.remove('animate__animated', 'animate__bounce');
        // element.classList.add('animate__animated', 'animate__bounce');
      }
    })
  }, [])
  useEffect(() => {
    // alert(JSON.stringify(me))
    //------------------------user setting change event------------------------//
    getCategory()
    const userSettingRef = ref(db, '/users/' + me.id + '/settings')
    return onValue(userSettingRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val()

        userSettings.current = data
      }
    })
    //------------------------end user setting change event------------------------//
  }, [])
  useEffect(() => {
    if (sessionNo) {
      getNewCard(sessionNo)
    }

    return () => {
      if (sessionNo != '' && me) {
        set(ref(db, 'onlineplayers/' + sessionNo + '/' + me.uid), {
          online: false,
          name: me.fname + ' ' + me.lname,
        })
      }
    }
  }, [sessionNo])
  useEffect(() => {
    agoraEngine.on('user-published', async (user, mediaType) => {
      // Subscribe to the remote user when the SDK triggers the "user-published" event.
      await agoraEngine.subscribe(user, mediaType)
      const remoteVideoTrack = user.videoTrack
      // remoteVideoTrack?.play('remote')
      const remoteAudioTrack = user.audioTrack
      remoteAudioTrack?.play('remotea')
      console.log('subscribe success')
      // eventsCallback("user-published", user, mediaType)
    })

    // Listen for the "user-unpublished" event.
    agoraEngine.on('user-unpublished', (user) => {
      console.log(user.uid + 'has left the channel')
    })

    if (sessionNo) {
      getRtcToken(sessionNo).then(async (result) => {
        // alert(result.data.token)
        await agoraEngine.join(
          result.data.appId,
          result.data.channel,
          result.data.token,
          moment().unix(),
        )
      })
    }
    return async () => {
      await agoraEngine.leave()
    }
  }, [sessionNo])

  const getRtcToken = (sessionNo) => {
    const functions = getFunctions()
    const getRtcToken = httpsCallable(functions, 'getRtcToken')

    return getRtcToken({
      channel: sessionNo,
      isPublisher: false,
    }).then((result) => {
      return result
    })
  }

  const getOnline = (sno) => {
    // alert(JSON.stringify(me))
    // return
    if (sno != '') {
      // set(ref(db,  "onlineplayers/"+sessionNo+"/" + auth.currentUser.uid ), {name:auth.currentUser.displayName?auth.currentUser.displayName:'annonymous user'});
      // Since I can connect from multiple devices or browser tabs, we store each connection instance separately
      // any time that connectionsRef's value is null (i.e. has no children) I am offline
      //const db = getDatabase();
      // console.log("onlineplayers/" + sno + "/" + auth.currentUser.uid)
      const reference = ref(db, 'onlineplayers/' + sno + '/' + me.id)

      // alert(route.params.user)
      // Set the /users/:userId value to true
      set(reference, {
        platform: 'Web',
        online: true,
        city: me.city,
        state: me.state,
        ischatenabled: me.ischatenabled ? me.ischatenabled : true,
        token: me.pushtoken ? me.pushtoken : null,
        name: me.fname + ' ' + me.lname,
        usertype: me.usertype,
      }).then(() => console.log('Online presence set'))

      // Remove the node whenever the client disconnects

      onDisconnect(reference)
        .update({ online: false })
        .then(() => console.log('Online presence set'))

      // .then(() => console.log('On disconnect function configured.'));
    }
  }

  const hideWinPatternShowGif = (d) => {
    setWinPatternModalVisible(false)

    setImage(d)
    if (d != '') {
      setImageModalVisible(true)
    }

    // setTimeout(setImageModalVisible.bind(null, false), 7000);
  }
  const animateCSS = (element, animation, prefix = 'animate__') =>
    // We create a Promise and return it
    new Promise((resolve, reject) => {
      const animationName = `${prefix}${animation}`
      const node = document.querySelector(element)

      node.classList.add(`${prefix}animated`, animationName)

      // When the animation ends, we clean the classes and resolve the Promise
      function handleAnimationEnd(event) {
        event.stopPropagation()
        node.classList.remove(`${prefix}animated`, animationName)
        resolve('Animation ended')
      }

      node.addEventListener('animationend', handleAnimationEnd, { once: true })
    })
  const getLocationRef = () => {
    const searchParams = new URLSearchParams(search)
    const param = searchParams.get('code')
    return Decrypt(param)
  }
  const getCategory = () => {
    let locationRef = getLocationRef()
    const arr = locationRef.split('/')
    return arr[2]
  }
  const getNewCard = (sessionno) => {
    // if (userType != 0) return;
    let rand = 0

    const userSettingRef = ref(db, '/users/' + me.id + '/lastsession')
    onValue(
      userSettingRef,
      (snapshot) => {
        let data_ = null
        if (snapshot.exists()) {
          data_ = snapshot.val()

          // lastsession.current = data
        }

        const patternCountRef = ref(db, '/patterncount') //get random pattern Id
        return onValue(patternCountRef, (snapshot) => {
          if (snapshot.exists()) {
            rand = Math.random() * snapshot.val()
            rand = Math.floor(rand)

            if (
              data_ &&
              data_.gameno == gameNo &&
              data_.sessionno == sessionNo
            ) {
              rand = data_.rand
            } else {
              rand = Math.random() * snapshot.val()
              rand = Math.floor(rand)
            }

            const patternRef = query(ref(db, 'patternswithindex/' + rand))
            onValue(patternRef, (snapshot) => {
              if (snapshot.exists()) {
                const data = snapshot.val()

                var tempdata = []
                for (var i = 0; i < data.length; i++) {
                  tempdata.push({ selected: false, no: data[i] })
                }

                setCardNo(data[12])

                setTiles(tempdata)

                updateSessionNo(data[12], sessionno, rand)
                let underlineindex = getRndInteger(0, 5)

                if (underlineindex == 0) {
                  setUnderline([true, false, false, false, false])
                } else if (underlineindex == 1) {
                  setUnderline([false, true, false, false, false])
                } else if (underlineindex == 2) {
                  setUnderline([false, false, true, false, false])
                } else if (underlineindex == 3) {
                  setUnderline([false, false, false, true, false])
                } else if (underlineindex == 4) {
                  setUnderline([false, false, false, false, true])
                }
              }
            })
          }
        })
      },
      {
        onlyOnce: true,
      },
    )
  }
  const updateSessionNo = (cardno, sessionno, rand) => {
    console.log(me.id)
    console.log(sessionNo)
    console.log(cardno)
    console.log(gameNo)
    const updates = {}
    if (sessionno != null) {
      updates['users/' + me.id + '/lastsession/sessionno'] = sessionno
      updates['users/' + me.id + '/lastsession/cardno'] = cardno
      updates['users/' + me.id + '/lastsession/gameno'] =
        currentgamenoint.current
      updates['users/' + me.id + '/lastsession/rand'] = rand
    } else {
      updates['users/' + me.id + '/lastsession/'] = null
    }
    update(ref(db), updates)
  }
  const selectNo = (index) => {
    if (drawnNumbers && drawnNumbers.includes(tiles[index].no)) {
      let t = [...tiles]
      t[index]['selected'] = true
      setTiles(t)

      if (userSettings.current.mutevalidchecks) {
        playSound(okaudio)
      }
    } else {
      if (userSettings.current.muteinvalidchecks) {
        playSound(noaudio)
      }
    }
    // console.log(t)
  }

  const playSound = async (obj) => {
    if (!userSettings.current.muteall) {
      new Audio(obj).play()
    }
  }

  const callBingo = () => {
    setCallBingoDisable(true)
    setTimeout(setCallBingoDisable.bind(this, false), 5000)
    let user_ = {
      zip: me ? me.zip : '',
      address: me ? (me.address ? me.address : me.city) : '',
      country: me ? me.country : '',
      email: me ? me.email : '',
      name: me ? me.fname + ' ' + me.lname : '',
      phone: me ? me.phone : '',
      userid: me.id,
    }
    console.log(user_)
    const functions = getFunctions()
    const checkWinner = httpsCallable(functions, 'checkWin')
    checkWinner({
      ref: getLocationRef(),
      cardno: cardno,
      patternname: currentGameName,
    }).then((result) => {
      console.log(result.data)
      if (result.data[0] == true) {
        addWinner(result.data[1], getGif(), 'player', user_, tiles)
      } else {
        addWinTry('player', user_)
      }
    })
  }
  const addWinner = async (pt, g, by, winnerdata, tempdata) => {
    var arr = []
    for (var j = 0; j < 25; j++) {
      var iswin = false
      if (pt.includes(j + 1)) {
        iswin = true
      }
      var a = {
        num: tempdata[j].no,
        iswin: iswin,
      }
      arr.push(a)
    }
    let data = {
      sessionno: sessionNo,
      winpattern: pt,
      gameno: gameNo,
      pattern: arr,
      numbersdrawn: numsDrawn,
      gif: g[0],
      gifm: g[1],
      user: winnerdata,
      ismanual: by == 'staff' ? true : false,
      isfromapp: by == 'staff' ? true : false,
      isstaff: by == 'staff' ? true : false,
      time: moment().unix(),
    }

    console.log(data)
    console.log(getLocationRef() + '/currentgame/lastwinner')
    set(ref(db, getLocationRef() + '/currentgame/lastwinner'), {
      lastwinner: data,
    })

    console.log('gamesessions/' + sessionNo + '/winners/')
    const docRef = await addDoc(
      collection(fsdb, 'gamesessions/' + sessionNo + '/winners/'),
      data,
    )

    // push(ref(db, 'gamesessions/'+sessionNo+'/winners/' ), data)
  }
  const addWinTry = async (by, winner) => {
    by == 'staff' ? setInputError('not a winner') : null

    let data = {
      sessionno: sessionNo,
      gameno: gameNo,
      numbersdrawn: numsDrawn,
      user: winner,
      ismanual: by == 'staff' ? true : false,
      isfromapp: by == 'staff' ? true : false,
      isstaff: by == 'staff' ? true : false,
      time: moment().unix(),
    }
    set(ref(db, getLocationRef() + '/currentgame/lastwintry'), {
      lastwintry: data,
    })
    // push(ref(db, 'gamesessions/' + sessionNo + '/wintries/'), data)
    console.log('gamesessions/' + sessionNo + '/wintries/' + data)
    const docRef = await addDoc(
      collection(fsdb, 'gamesessions/' + sessionNo + '/wintries/'),
      data,
    ).catch((e) => {
      console.log(e)
    })
    console.log('Document written with ID: ', docRef.id)
  }
  const getGif = () => {
    let random = getRndInteger(0, winGifs.length)
    let gif = winGifs[random].m
    let gif_desktop = winGifs[random].d
    return [gif_desktop, gif]
  }
  function getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min)) + min
  }
  const getWinGifs = () => {
    //  alert(   route.params.locationref)
    const dbRef = ref(getDatabase())
    get(child(dbRef, `groups/${getGroupID()}/wingifs`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          let wg = []
          snapshot.forEach(function (item) {
            wg.push(item.val())
          })
          setWiGifs(wg)
        } else {
          console.log('No gif data available')
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
  const getGroupID = () => {
    // let gid=0;

    ///groupsbycategory/1/01/locations/1
    const myArray = getLocationRef().split('/')

    return myArray[3]
  }
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div id="remotea" class="holder--video-remote waiting">
          {' '}
        </div>
        {videoModalVisible && (
          <VideoModal
            play={videoModalVisible}
            url={videourl}
            message={message}
            func={() => setVideoModalVisible(false)}
          />
        )}

        <div className={styles.sideContainer}>
          {showAll && (
            <div
              style={{
                flex: '1',
                height: '100vh',
                alignItems: 'center',
                display: ' flex',
                justifyContent: 'flex-end',
              }}
              className={'animate__animated animate__slideInRight'}
            >
              <ShowAll
                category={getCategory()}
                loteria={loteriaImages.current.thumbs}
                back={gameBackground[gameNo - 1]}
                backcolor={backcolor}
                drawnNumbers={drawnNumbers}
                onClick={() => setShowAll(false)}
              />
            </div>
          )}
        </div>

        <div
          style={{
            position: 'relative',
            backgroundSize: 'cover',
            aspectRatio: '8/16',
            backgroundImage: `url(${gameBackground[gameNo - 1]})`,
            zIndex: 100000,
          }}
          className={styles.middlContainer}
        >
          {winPatternModalVisible && (
            <WinPattern
              category={getCategory()}
              loteria={loteriaImages.current.thumbs}
              back={userSettings.current.dauber}
              isWinPattern={winPatternModalVisible}
              tiles={tilesForModal}
            />
          )}
          {noWin && (
            <NoWin
              onClick={() => setNoWin(false)}
              back={backcolor}
              isWinPattern={winPatternModalVisible}
              tiles={tilesForModal}
            />
          )}
          {imageModalVisible && (
            <ImageModal
              autoClose={imageAutoClose}
              image={image}
              parentFunction={() =>
                setTimeout(setImageModalVisible.bind(null, false), 7000)
              }
            />
          )}
          {LImageModalVisible && (
            <LImageModal
              image={image}
              parentFunction={() =>
                setTimeout(setLImageModalVisible.bind(null, false), 2000)
              }
            />
          )}
          {showAlert && (
            <Alert message={message} onClick={() => setShowAlert(false)} />
          )}
          {showSoundSettings && (
            <SoundSettings
              settings={userSettings.current}
              userid={me.id}
              onClick={() => setShowSoundSettings(false)}
            />
          )}
          {showDauberModal && (
            <DauberModal
              dauberColor={userSettings?.current?.dauber}
              userid={me.id}
              onClick={() => setShowDauberModal(false)}
            />
          )}
          <div>
            <button
              type="button"
              style={{
                width: '5vh',
                aspectRatio: '1',
                alignItems: 'center',
                flexDirection: 'column',
                display: 'flex',
                margin: '1%',
              }}
              onClick={() => history.goBack()}
            >
              <div
                className="image-button-img"
                style={{
                  borderRadius: '100%',
                  flex: 3,
                  padding: 3,
                  display: 'flex',
                  width: '100%',
                  backgroundImage: 'linear-gradient(#ffe500,#ff7a01)',
                }}
              >
                <div
                  style={{
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: 1,
                    backgroundColor: `${backcolor}`,
                    backgroundImage: `url(${images.iconBackClear})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                ></div>
              </div>
            </button>
          </div>
          <div className={styles.topRow}>
            <div
              style={{
                display: 'flex',
                flex: 1,
                marginTop: '2%',
                marginBottom: '2%',
              }}
            >
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <p className={styles.totalLast}>TOTAL</p>
                <div
                  style={{
                    fontSize: '3vh',
                    fontFamily: 'GamerStation',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#ffffff99',
                    border: '3px solid white',
                    borderLeft: 0,
                    width: '100%',
                    borderTopRightRadius: getCategory() == 4 ? 25 : 50,
                    borderBottomRightRadius: getCategory() == 4 ? 25 : 50,
                    flex: 1,
                  }}
                >
                  {/* <div> */}

                  {numsDrawn}
                  {/* </div> */}
                </div>
              </div>
              <div style={{ width: '50%', display: 'flex' }}>
                {currentNo && getCategory() != 4 ? (
                  <div
                    id="current-no"
                    style={{
                      fontSize: '3vh',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundSize: 'contain',
                      backgroundImage: `url(${getGameBallImage(
                        currentNo?.substring(2),
                      )})`,
                      flex: 1,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      fontFamily: 'GamerStation',
                    }}
                  >
                    {currentNo}
                  </div>
                ) : (
                  <div
                    id="current-no"
                    style={{
                      fontSize: '3vh',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundSize: 'contain',
                      backgroundImage: `url(${
                        loteriaImages.current?.thumbs?.find(
                          (item_) => item_.id == currentNo?.substring(2),
                        )?.url
                      })`,
                      flex: 1,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      fontFamily: 'GamerStation',
                    }}
                  ></div>
                )}
              </div>
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <p className={styles.totalLast}>LAST</p>
                <div
                  style={{
                    padding: '8%',
                    flex: 1,
                    backgroundColor: '#ffffff99',
                    border: '3px solid white',
                    borderRight: 0,
                    width: '100%',
                    display: 'flex',
                    borderTopLeftRadius: getCategory() == 4 ? 25 : 50,
                    borderBottomLeftRadius: getCategory() == 4 ? 25 : 50,
                    flex: 1,
                  }}
                >
                  {lastNo && getCategory() != 4 ? (
                    <div
                      id="last-no"
                      style={{
                        fontFamily: 'GamerStation',
                        backgroundSize: 'contain',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundImage: `url(${getGameBallImage(
                          lastNo?.substring(2),
                        )})`,
                        flex: 1,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                      }}
                    >
                      {lastNo}
                    </div>
                  ) : (
                    <div
                      id="last-no"
                      style={{
                        fontFamily: 'GamerStation',
                        backgroundSize: 'contain',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundImage: `url(${
                          loteriaImages.current?.thumbs?.find(
                            (item_) => item_.id == lastNo?.substring(2),
                          )?.url
                        })`,
                        flex: 1,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                      }}
                    ></div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.gameName}>
              {' '}
              Game #{gameNo} {currentGameName}
            </div>
          </div>
          <div className={styles.middleRow}>
            <div
              className={styles.gridContainerB}
              style={{ gridGap: 8, padding: 8, position: 'relative' }}
            >
              <div style={{ aspectRatio: '.75' }} className={styles.gridItem}>
                <ImageButton
                  label="Show All"
                  image={images.btn_showAll}
                  onClick={() => setShowAll(!showAll)}
                  back={backcolor}
                />
              </div>
              <div style={{ aspectRatio: '.75' }} className={styles.gridItem}>
                <ImageButton
                  back={backcolor}
                  label="Call Bingo"
                  image={images.btn_callBingo}
                  isLoading={callBingoDisable}
                  onClick={() => {
                    callBingoDisable ? null : callBingo()
                  }}
                />
              </div>
              <div style={{ aspectRatio: '.75' }} className={styles.gridItem}>
                <ImageButton
                  back={backcolor}
                  label="Dauber"
                  image={images.btn_dauberMarker}
                  onClick={() => setShowDauberModal(true)}
                />
              </div>
              <div style={{ aspectRatio: '.75' }} className={styles.gridItem}>
                <ImageButton
                  back={backcolor}
                  label="Mute"
                  image={images.btn_mute}
                  onClick={() => setShowSoundSettings(true)}
                />
              </div>
              <div style={{ aspectRatio: '.75' }} className={styles.gridItem}>
                <ImageButton
                  back={backcolor}
                  label="Chat"
                  image={images.btn_chat}
                  onClick={() => setIsChatShow(!isChatShow)}
                />
              </div>
            </div>
          </div>
          <div className={styles.bottomRow}>
            <div
              className={styles.gridContainer}
              style={{ gridGap: 2, padding: 4, backgroundColor: '#00000040' }}
            >
              {wordBingo.map(function (item, i) {
                return (
                  <div
                    key={'b' + i}
                    style={{
                      fontSize: '4vh',
                      fontFamily: 'GamerStation',
                      aspectRatio: '1',
                      backgroundSize: 'cover',
                      backgroundImage: `url(${
                        getCategory() == 4
                          ? gameBallImagesL[i]
                          : gameBallImages[i]
                      })`,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      textDecorationLine: underline[i] ? 'underline' : '',
                    }}
                    className={styles.gridItem}
                  >
                    {getCategory() != 4 && item}
                  </div>
                )
              })}
            </div>

            <div
              className={styles.gridContainer}
              ref={ref_}
              style={{ gridGap: 2, padding: 4, aspectRatio: '1' }}
            >
              {tiles.map(function (item, i) {
                return (
                  <Button
                    type="button"
                    onClick={() => selectNo(i)}
                    style={{}}
                    className={styles.gridItem}
                  >
                    {/* {item.no} */}

                    {getCategory() == 4 ? (
                      <LCardButton
                        img={
                          loteriaImages.current?.thumbs?.find(
                            (item_) => item_.id == item.no,
                          )?.url
                        }
                        currentGameName={currentGameName}
                        index={i}
                        back={userSettings.current.dauber}
                        size={width}
                        item={item}
                      />
                    ) : (
                      <CardButton
                        currentGameName={currentGameName}
                        index={i}
                        back={userSettings.current.dauber}
                        size={width}
                        item={item}
                      />
                    )}
                  </Button>
                )
              })}
            </div>
          </div>
        </div>

        <div className={styles.sideContainer}>
          {sessionNo && isChatShow && (
            <div
              style={{
                flex: '1',
                height: '100vh',
                alignItems: 'center',
                display: ' flex',
                // justifyContent: 'center'
              }}
              className={'animate__animated animate__slideInLeft'}
            >
              {' '}
              <Chat
                back={gameBackground[gameNo - 1]}
                sessionNo={sessionNo}
                userId={me.id}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

// Notifications.propTypes = {}
// Notifications.defaultProps = {}

export default Game
