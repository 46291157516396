import React from 'react'

import styles from './ImageModal.module.scss'

import { LazyLoadImage } from 'react-lazy-load-image-component';
const ImageModal = ({
  image, parentFunction, autoClose
}) => {


  return <div  >
    <div className={styles.wrapper} >
      <div style={{ display: 'flex', flexDirection: 'column', backgroundSize: 'cover', flex: 1, backgroundColor: 'black', justifyContent: 'center', alignItems: 'center' }}>


        {/* <img style={{ width: '100%' }} src={image} /> */}
        {/* <div class="animate__animated animate__bounceIn" > */}
        <LazyLoadImage

          // alt={"image.alt"}
          // height={image.height}
          placeholderSrc="https://placehold.co/600x400?text=Hello+World"
          loading='lazy'
          effect="blur"
          src={image} // use normal <img> attributes as props
          onLoad={() => {
            autoClose ? parentFunction() : null
          }}
          height={'auto'}
          width={'100%'} />

        {/* </div> */}

      </div>
    </div>
  </div>
}



export default ImageModal
