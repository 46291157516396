export const path = {
  login: '/login',
  signup: '/signup',
  profile: '/profile',
  resetPassword: '/reset-password',
  confirmEmail: '/confirm-email',
  dashboard: '/dashboard',
  notifications: '/notifications',
  schedule: '/schedule',
  locations: '/locations',
  game: '/game',
  wallet: '/wallet',
  // profile: '/profile/',
  edit: '/edit',
  delete: '/delete',
  reset: '/reset',
  cuoponPreview: '/cuoponPreview',
  map: '/map',
}
