import React, { useEffect, useState, useRef } from 'react'
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styles from './profile.module.scss'
import { images } from 'theme'
import { path } from 'utils/const'
import { actions } from 'slices/app.slice'
import moment from 'moment'
import ProfileActionButton from 'pageComponents/ProfileActionButton'
import GameModal from 'pageComponents/GameModal'
import SoundSettings from 'pageComponents/SoundSettings'
import { getDatabase, onValue, ref } from 'firebase/database'

const Profile = () => {
  const { me } = useSelector((state) => state.app)
  const history = useHistory()
  const db = getDatabase()
  const dispatch = useDispatch()
  const [showSoundSettings, setShowSoundSettings] = useState(false)
  const userSettings = useRef({
    dauber: '#6764cb',
    muteall: false,
    muteinvalidchecks: false,
    muterobo: false,
    mutevalidchecks: false,
    mutewinsounds: false,
  })
  const [backcolor, setBackcolor] = useState('purple')
  useEffect(() => {
    //------------------------user setting change event------------------------//

    const userSettingRef = ref(db, '/users/' + me.id + '/settings')
    return onValue(userSettingRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val()

        userSettings.current = data
      }
    })
    //------------------------end user setting change event------------------------//
  }, [])
  const soundsModalContent = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        modal ,dnfkjdkf jksdjs
      </div>
    )
  }

  const openModal = () => {
    return <GameModal content={soundsModalContent()}></GameModal>
  }

  return (
    <>
      <div className={styles.root}>
        <div className={styles.container}>
          <div className={styles.sideContainer}></div>

          <div
            style={{
              backgroundSize: 'cover',
              aspectRatio: '8/16',
              background: `url(${images.Sunburst}) , linear-gradient(125.07deg, #ff3b86 0%, #9d7fe3 66.18%)`,
            }}
            className={styles.middleContainer}
          >
            {/* <Switch>
              <Route path={path.profile}>
                <Main />
              </Route>
              <Route path={path.edit}>
                <Edit />
              </Route>
              <Route path={path.delete}>
                <Delete />
              </Route>
              <Route path={path.reset}>
                <Reset />
              </Route>

              <Redirect to={path.profile} />
            </Switch> */}

            <div className={styles.menuBar}>
              <div className={styles.width20}>
                <button
                  type="button"
                  style={{
                    width: '5vh',
                    aspectRatio: '1',
                    alignItems: 'center',
                    flexDirection: 'column',
                    display: 'flex',
                  }}
                  onClick={() => history.goBack()}
                >
                  <div
                    className="image-button-img"
                    style={{
                      borderRadius: '100%',
                      flex: 3,
                      padding: 3,
                      display: 'flex',
                      width: '100%',
                      backgroundImage: 'linear-gradient(#ffe500,#ff7a01)',
                    }}
                  >
                    <div
                      style={{
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flex: 1,
                        backgroundColor: `${backcolor}`,
                        backgroundImage: `url(${images.iconBackClear})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        width: '54px',
                      }}
                    ></div>
                  </div>
                </button>
              </div>
            </div>

            {/**profile page start */}

            <div style={{ marginLeft: '16px', marginRight: '16px' }}>
              <div
                style={{
                  background: `url(${images.previewCard})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'contain',
                  alignSelf: 'center',
                  cursor: 'pointer',
                  backgroundRepeat: 'no-repeat',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative',
                  aspectRatio: '16/9',
                  backgroundSize: 'contain',
                }}
              >
                <img
                  style={{
                    width: '75px',
                    top: 0,
                    position: 'absolute',
                    transform: ' translateY(-25%)',
                  }}
                  src={images.imageProfile}
                ></img>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative',
                    }}
                  >
                    <div
                      style={{
                        fontFamily: 'GamerStation',
                        color: '#FFDF00',
                        textAlign: 'left',
                        fontSize: '3vh',
                      }}
                    >
                      {me.fname}
                    </div>
                    <div
                      style={{
                        fontFamily: 'BebasNeue',
                        color: '#fff',
                        textAlign: 'left',
                        fontSize: '2vh',
                      }}
                    >
                      {me.email}
                    </div>
                    <div
                      style={{
                        fontFamily: 'BebasNeue',
                        color: '#fff',
                        textAlign: 'left',
                        fontSize: '2vh',
                      }}
                    >
                      {me.phone}
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'space-evenly',
                      transform: 'translateY(0%)',
                      position: 'absolute',
                      bottom: 0,
                    }}
                  >
                    <div
                      className={styles.roundFrame}
                      style={{
                        background: `url(${images.roundFrame})`,
                        backgroundPosition: 'center',
                        backgroundColor: 'purple',
                        borderRadius: 100,
                        backgroundSize: 'cover',
                        height: '55px',
                        width: '55px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                      }}
                    >
                      <div className={styles.frameText}>
                        <span>
                          <img src={images.usaFlag}></img>
                        </span>
                        <span> {me.country}</span>
                      </div>
                    </div>
                    <div
                      className={styles.roundFrame}
                      style={{
                        background: `url(${images.roundFrame})`,
                        backgroundPosition: 'center',
                        backgroundColor: 'green',
                        borderRadius: 100,
                        backgroundSize: 'cover',
                        height: '55px',
                        width: '55px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                      }}
                    >
                      <div className={styles.frameText}>
                        <span>
                          <img src={images.calendar}></img>
                        </span>
                        <span>
                          {moment(me.dob).format('MM/DD/YYYY') !==
                          'Invalid date'
                            ? moment(me.dob).format('MM/DD/YYYY')
                            : ' '}
                        </span>
                      </div>
                    </div>
                    {/* <div className={styles.roundFrame} style={{
                      background: `url(${images.roundFrame})`, backgroundPosition: 'center', backgroundColor: "#e90c00", borderRadius: 100,
                      backgroundSize: 'cover', height: '55px', width: '55px', alignItems: 'center', justifyContent: 'center', display: 'flex'
                    }}>
                      <div className={styles.frameText}>
                        <span>🔥</span>
                        <span>2</span>
                      </div>
                    </div> */}
                    <div
                      className={styles.roundFrame}
                      style={{
                        background: `url(${images.roundFrame})`,
                        backgroundPosition: 'center',
                        backgroundColor: '#5457A3',
                        borderRadius: 100,
                        backgroundSize: 'cover',
                        height: '55px',
                        width: '55px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                      }}
                    >
                      <div className={styles.frameText}>
                        <span>
                          {' '}
                          {me.gender == 'male'
                            ? '♂'
                            : me.gender == 'female'
                            ? '♀'
                            : '⚤'}
                        </span>
                        <span> {me.gender}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '16px',
                flex: 1,
                gap: '16px',
                overflowY: 'auto',
              }}
            >
              <ProfileActionButton
                onPress={() => {
                  history.push(path.edit)
                }}
                title={'edit profile'}
                imageName={images.iconEdit}
                btnSource={images.btnProfile}
              ></ProfileActionButton>
              <ProfileActionButton
                onPress={() => {
                  history.push(path.reset)
                }}
                title={'reset password'}
                imageName={images.iconPassword}
                btnSource={images.btnProfile}
              ></ProfileActionButton>
              <ProfileActionButton
                onPress={() => setShowSoundSettings(true)}
                title={'sound settings'}
                imageName={images.iconSettings}
                btnSource={images.btnProfile}
              ></ProfileActionButton>
              <ProfileActionButton
                onPress={() => {
                  history.push(path.delete)
                }}
                title={'delete account'}
                imageName={images.iconDelete}
                btnSource={images.btnLandscapeRed}
              ></ProfileActionButton>
              <ProfileActionButton
                onPress={() => dispatch(actions.logout())}
                title={'logout'}
                imageName={images.iconLogout}
                btnSource={images.btnLandscapeRed}
              ></ProfileActionButton>
            </div>
            {showSoundSettings && (
              <SoundSettings
                settings={userSettings.current}
                userid={me.id}
                onClick={() => setShowSoundSettings(false)}
              />
            )}
            {/**profile page end */}
          </div>

          <div className={styles.sideContainer}></div>
        </div>
      </div>
    </>
  )
}

// Notifications.propTypes = {}
// Notifications.defaultProps = {}

export default Profile
