import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/performance'
import 'firebase/compat/analytics'
import config from './config'

firebase.initializeApp(config.firebase)
firebase.analytics()

export const auth = firebase.auth()
export const storage = firebase.storage().ref()
export const performance = firebase.performance()
export const firestore = firebase.firestore()

export default firebase
