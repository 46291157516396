import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/Modal'
import FontIcon from 'components/FontIcon'
import Button from 'components/Button'
import { useHistory } from 'react-router-dom'
import styles from './gameModal.module.scss'
import { path } from 'utils/const'
import { images } from 'assets'
import CardButton from 'pageComponents/CardButton'

const GameModal = ({ content, onClick, top = null, left = null }) => {
  // alert(JSON.stringify(groupedData))
  return (
    <>
      <div
        style={{
          aspectRatio: '8/16',
          backgroundSize: 'cover',
          height: '100%',
          position: 'absolute',
          zIndex: 10000,
          display: 'flex',
          flexDirection: 'column',
          top: top,
          left: left,
        }}
        className={`modal-screen`}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            backgroundSize: 'cover',
            flex: 1,
            backgroundColor: '#000000bf',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            className="animate__animated animate__bounceIn"
            style={{
              width: '94%',
              backgroundColor: '#ecedef',
              borderRadius: 16,
            }}
          >
            <div
              className={styles.imgButtonImg}
              style={{
                borderRadius: 16,
                flex: 2,
                padding: 3,
                display: 'flex',
                backgroundImage: 'linear-gradient(#ffe500,#ff7a01)',
              }}
            >
              <div
                className={styles.modalBackground}
                style={{
                  padding: '8%',
                  borderRadius: 16,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  flex: 1,
                }}
              >
                {content}
                {/* <Button onClick={() => onClick()} label={'close'}></Button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

// SentResetPassword.propTypes = {
//   email: PropTypes.string,
//   isOpen: PropTypes.bool,
//   toggle: PropTypes.func,
//   onSubmit: PropTypes.func,
// }

// SentResetPassword.defaultProps = {
//   email: '',
//   isOpen: false,
//   toggle: () => {},
//   onSubmit: () => {},
// }

export default GameModal
