import React from 'react'
import PropTypes from 'prop-types'
import './input.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

const Input = ({
  label,
  type,
  name,
  value,
  placeholder,
  onChange,
  onKeyDown,
  maxLength,
  className,
  style,
  error,
  disabled,
  iconName,
  iconColor,

  ...others
}) => (
  <div className={`form-group ${className}`} style={style}>
    {label && <p className="input-label">{placeholder}</p>}
    <div className="input-container">
      <div style={{ borderRadius: '50px', flex: 2, padding: 3, display: 'flex', backgroundImage: "linear-gradient(#ffe500,#ff7a01)", height: '50px' }}>
        {/* linear-gradient(125.07deg,#ff3b86 0%,#9d7fe3 66.18%) */}
        {
          // iconName &&
          // <div className='input-icon'>
          //   <FontAwesomeIcon style={{ marginRight: '5px', color: iconColor }} icon={icon({ name: 'map' })} />
          // </div>
        }
        <input
          value={value}
          type={type}
          name={name}
          onChange={onChange}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          maxLength={maxLength}
          className={`input ${error ? 'form-control is-invalid' : 'form-control'}`}
          disabled={disabled}
          {...others}
        />
      </div>
      <div className="invalid-feedback">{error}</div>
    </div>
  </div>
)

Input.propTypes = {
  type: PropTypes.string,
  label: PropTypes.oneOfType([null, PropTypes.string]),
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  maxLength: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.shape({}),
  error: PropTypes.string,
  disabled: PropTypes.bool,
}

Input.defaultProps = {
  type: 'text',
  label: null,
  name: '',
  value: '',
  placeholder: '',
  onChange: () => { },
  onKeyDown: () => { },
  maxLength: '150',
  className: '',
  style: {},
  error: '',
  disabled: false,
}

export default Input
