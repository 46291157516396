import React from 'react'
import PropTypes from 'prop-types'
import styles from './colorCard.module.scss'

const ColorCard = ({
  color,
  dauberColor,
  onClick
}) => {

  return (
    <>
      <div className={styles.colorCardWrapper} onClick={() => onClick(color)}>
        <div className={styles.colorCard} style={{ background: color }}>
          {dauberColor == color &&
            <span>&#10003;</span>
          }
        </div>
      </div>
    </>
  )
}




export default ColorCard
