/* eslint-disable no-undef */
import React, { useEffect, useState, useRef } from 'react'
import { useHistory, } from 'react-router-dom'
import styles from './map.module.scss'
import { images } from 'theme'
import moment from 'moment'
var _ = require("lodash");

import {
  getFirestore,
  collection,
  query as fsquery,
  where,
  onSnapshot,
  orderBy,
  limit,
} from "firebase/firestore";

import { auth } from 'utils/firebase'
import { GoogleMap, Marker, useJsApiLoader, Autocomplete } from '@react-google-maps/api';

// import Autocomplete from "react-google-autocomplete";
import { getDatabase, onValue, ref } from 'firebase/database';
import { useSelector } from 'react-redux';
import Input from 'components/Input';
import Select from 'react-dropdown-select';
import ReactSlick from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LocationCard from 'pageComponents/LocationCard';
const Map = () => {
  const slideRef = useRef(null);
  const libraries = ['places'];
  const autocompleteRef = useRef();
  const history = useHistory();
  const [backcolor, setBackcolor] = useState("purple");
  const [searchText, setSearchText] = useState("");
  const [radius, setRadius] = useState({ label: "Within 5Miles", value: 5 })
  const [isloading, setIsLoading] = useState(false);
  const [markers, setMarkers] = useState([]);
  const { me } = useSelector((state) => state.app)
  const fsdb = getFirestore();
  const [slideWidth, setSlideWidth] = useState(200);
  const countries = [
    { label: "Within 3Miles", value: 3 },
    { label: "Within 5Miles", value: 5 },
    { label: "Within 10Miles", value: 10 },
    { label: "Within 20Miles", value: 20 },
  ];
  const settings = {
    className: "center",
    centerMode: false,
    infinite: false,
    centerPadding: "80px",
    slidesToShow: 1.2,
    speed: 500,
    arrows: false,
  };
  const [center, setCenter] = useState({
    lat: 41.751520,
    lng: -87.768970
  });



  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBHkYS5t5ygmNxExiV6o6ufzG1anjrZCBY", libraries
  })

  const containerStyle = {
    width: '100%',
    height: '100%',
    position: 'absolute', flex: 1,
    top: 0
  };


  useEffect(() => {

    setSlideWidth(slideRef.current.clientWidth)
  }, []);

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    map.setZoom(9)

    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  function onLoadA(autocomplete) {
    setSearchResult(autocomplete);
  }
  const arePointsNear = (checkPoint, centerPoint, km) => {
    var ky = 40000 / 360;

    var kx = Math.cos((Math.PI * centerPoint.latitude) / 180.0) * ky;

    var dx = Math.abs(centerPoint.longitude - checkPoint.longitude) * kx;
    var dy = Math.abs(centerPoint.latitude - checkPoint.latitude) * ky;

    return Math.sqrt(dx * dx + dy * dy) <= km * 1.6;
  };



  const getBars = async (location, radius) => {
    // alert(radius.value)
    // setMarkers([])
    if (searchText == "") {
      return
    }
    const db = getDatabase();
    let category = 1;
    const liveref = ref(db, "groupsbycategory/" + category);
    onValue(
      liveref,
      async (snapshot) => {
        let tempArray = [];
        snapshot.forEach(function (item) {
          var locations = item.val().locations;

          const locationKeys = Object.keys(locations);
          locationKeys.forEach((key, index) => {
            if (
              locations[key].country &&
              locations[key].country.val == me.country
            ) {
              locations[key].groupid = item.key;
              locations[key].locationid = key;

              if (locations[key].cordinates) {
                if (arePointsNear(locations[key].cordinates, location, radius.value)) {
                  let obj = locations[key]
                  obj.currentgame = null
                  obj.currentgame = null
                  tempArray.push(obj);
                }
              }
            }
          });
        });
        // console.log(tempArray)

        let chunks = sliceIntoChunks(tempArray, 25);

        let la = 0;
        let lo = 0;

        // alert(geolocation.coords.latitude)
        // if (initial) {
        //   la = geolocation.coords.latitude;
        //   lo = geolocation.coords.longitude;

        // } else {

        la = location.latitude;
        lo = location.longitude;
        // }


        if (tempArray.length > 0) {












          try {
            let arrayset = [];
            chunks.forEach(async (element) => {



              let strDestinations = []
              for (var i = 0; i < element.length; i++) {
                strDestinations.push({ lat: Number(element[i]["cordinates"].latitude), lng: Number(element[i]["cordinates"].longitude) })
              }

              const service = new window.google.maps.DistanceMatrixService();
              const origin1 = { lat: location.latitude, lng: location.longitude };
              // const origin2 = "Greenwich, England";
              const destinationA = "Stockholm, Sweden";
              const destinationB = { lat: 50.087, lng: 14.421 };
              // console.log(JSON.stringify(strDestinations))
              const request = {
                origins: [origin1],
                destinations: strDestinations,
                travelMode: window.google.maps.TravelMode.DRIVING,
                unitSystem: window.google.maps.UnitSystem.METRIC,
                avoidHighways: false,
                avoidTolls: false,
              };
              service.getDistanceMatrix(request).then((response) => {
                // console.log(response)



                let rows = response["rows"][0]["elements"];

                for (var i = 0; i < rows.length; i++) {
                  element[i]["meters"] = Number(rows[i]["distance"]["value"]);
                  element[i]["distance"] =
                    (
                      (Number(rows[i]["distance"]["value"]) / 1000) *
                      0.621371
                    ).toFixed(2) + " miles";
                  element[i]["distance_value"] = rows[i]["distance"]["value"];
                  element[i]["timetaken"] = rows[i]["duration"]["text"];
                  element[i]["time_value"] = rows[i]["duration"]["value"];
                }

                // console.log(element.length);

                arrayset = arrayset.concat(element);
                arrayset = _.orderBy(arrayset, ["meters"], ["asc"]); // Use Lodash to sort array by 'name'
                // console.log(arrayset)
                setMarkers(arrayset);



              });









            });
          } catch (error) {
            setMarkers(tempArray);
          }

          // console.log(arrayset.length)
        }


        setIsLoading(false);
      },
      {
        onlyOnce: true,
      }
    );
  };
  const sliceIntoChunks = (arr, chunkSize) => {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  };


  const onLoadAutocomplete = (autocomplete) => {
    autocompleteRef.current = autocomplete
  }

  const handlePlaceChanged = (d) => {
    // console.log(autocompleteRef.current.getPlace())
    const { geometry, formatted_address } = autocompleteRef.current.getPlace();
    setSearchText(formatted_address)
    console.log(JSON.stringify(geometry))
    setCenter({ lat: geometry.location.lat(), lng: geometry.location.lng() })
    getBars(
      {
        latitude: geometry.location.lat(),
        longitude: geometry.location.lng(),
      },
      radius
    );


    // const bounds = new window.google.maps.LatLngBounds();
    // if (geometry.viewport) {
    // 	bounds.union(geometry.viewport);
    // } else {
    // 	bounds.extend(geometry.location);
    // }
    // mapRef.current.fitBounds(bounds);
  }

  const handleOnChangeSelect = (name, value) => {
    console.log(value[0].value)
    setRadius(value[0])
    getBars(
      {
        latitude: center.lat,
        longitude: center.lng,
      },
      value[0]
    );

  }
  const Item = ({ item }) => {
    const [isPointMoved, setIsPointMoved] = useState(false)
    return (
      // <div
      //   // onClick={}
      //   style={{
      //     // backgroundImage: `url(${item.large})`,
      //     backgroundPosition: 'center',
      //     backgroundSize: 'contain',
      //     width: slideWidth * .6,
      //     height: slideWidth * .6,
      //     alignSelf: 'center',
      //     cursor: 'pointer',
      //     backgroundRepeat: 'no-repeat'
      //   }}
      //   onPointerDown={() => {
      //     // setIsPointMoved(false)
      //   }}
      //   onPointerMove={() => {
      //     // setIsPointMoved(true)
      //   }}
      // // onPointerUp={() => {
      // //   // if point moved, don't do any thing
      // //   if (isPointMoved) {
      // //     setIsPointMoved(true)
      // //     return
      // //   }
      // //   // if point haven't moved, handle the click event as you wish
      // //   // handleClick()
      // //   item.onPress()
      // // }}
      // >
      //   dfgdf


      // </div>

      <LocationCard

        dateobject={{
          date: item.intday,
          time: item.time,
        }}
        ismaplocation={true}
        distance={item.distance ? item.distance : "-"}
        timetaken={item.timetaken}
        // distance={{ text: item.distancetext, value: item.distancevalue }}
        locationid={item.locationid}
        location={item.name}
        userlastsession={item.userlastsession}
        status={item.status}
        btnEnabled={item.opened}
        address={item.address}
        address2={item.city +
          ", " +
          item.state +
          ", " +
          item.zip}
        phone={item.phone}
        schedule={item.day + "s" + " at " + moment(item.time).format("hh:mm A")
        }

        pic={item.imageurl}
        // locationRef={
        //   "/groupsbycategory/" +
        //   getCategory() +
        //   "/" +
        //   item.groupid +
        //   "/locations/" +
        //   item.locationid
        // }
        onClick={async () => {

          return null
        }
        }
      />
    )
  }
  return (
    <>
      <div className={styles.root}>
        <div className={styles.container}>

          <div className={styles.sideContainer}>

          </div>

          <div ref={slideRef} style={{ position: 'relative', backgroundSize: 'cover', aspectRatio: '8/16', background: `url(${images.Sunburst}) , linear-gradient(125.07deg, #ff3b86 0%, #9d7fe3 66.18%)` }} className={styles.middleContainer}>

            <div className={styles.menuBar}>

              <div className={styles.width20}>
                <button
                  type="button"
                  style={{ width: '5vh', aspectRatio: '1', alignItems: 'center', flexDirection: 'column', display: 'flex', }}
                  onClick={() => history.goBack()}
                >
                  <div className='image-button-img' style={{ borderRadius: '100%', flex: 3, padding: 3, display: 'flex', width: '100%', backgroundImage: "linear-gradient(#ffe500,#ff7a01)", }}>
                    <div style={{ borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, backgroundColor: `${backcolor}`, backgroundImage: `url(${images.iconBackClear})`, backgroundSize: 'cover', backgroundPosition: 'center', width: '54px' }}>
                    </div>
                  </div>
                </button>
              </div>

            </div>
            {/* <div className={styles.middleRow} style={{ flex: 1, overflowY: 'auto', position: 'relative' }}> */}

            {isLoaded ? (
              <GoogleMap

                mapContainerStyle={containerStyle}
                center={center}
                zoom={1}
                onLoad={onLoad}
                onUnmount={onUnmount}
                disableDefaultUI={true}
                mapTypeControl={false}
              >
                <Autocomplete
                  onLoad={onLoadAutocomplete}
                  onPlaceChanged={handlePlaceChanged}
                >
                  <div style={{
                    width: '100%', padding: '0 16px', position: 'absolute',
                    top: 'Calc(5vh + 32px)',
                    display: 'flex',
                    gap: '16px',
                    flexDirection: 'row-reverse'
                  }}>

                    <div className="input-container">
                      <Input type='text'
                        placeholder={'Enter your zip code'}


                        onChange={e => setSearchText(e.target.value)}

                        value={searchText}
                        iconName={'search'}
                        style={{
                          flex: 1,
                          zIndex: 100,

                          // marginLeft: '-120px'
                        }}

                      ></Input>

                    </div>
                    <div className={styles.dropDown} style={{ borderRadius: '50px', flex: 2, padding: 3, display: 'flex', backgroundImage: "linear-gradient(#ffe500,#ff7a01)", height: '50px' }}>
                      <Select
                        values={[radius]}
                        options={countries}
                        onChange={(values) => handleOnChangeSelect("country", values)}
                      />
                    </div>
                    {/* search bar gose here */}
                  </div>
                  {/* <input
                    type='text'
                    placeholder='Search Location'
                    style={{
                      zIndex: 100,
                      boxSizing: 'border-box',
                      border: '1px solid transparent',
                      width: '240px',
                      height: '38px',
                      padding: '0 12px',
                      borderRadius: '3px',
                      boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                      fontSize: '14px',
                      outline: 'none',
                      textOverflow: 'ellipses',
                      position: 'absolute',
                      right: '8%',
                      top: 'Calc(5vh + 32px)',
                      marginLeft: '-120px'
                    }}
                  /> */}
                </Autocomplete>
                {markers.map(marker => (

                  <Marker
                    key={marker.locationid}
                    position={{ lat: Number(marker.cordinates.latitude), lng: Number(marker.cordinates.longitude) }}

                  // onRightClick={() => props.onMarkerRightClick(marker)}
                  />
                ))}


                <ReactSlick style={{ width: slideWidth, position: 'absolute', bottom: 0, left: 0 }} {...settings}>
                  {markers.map((item, index) => (

                    <Item key={index + 'itemindex'} item={item} />
                  ))}
                </ReactSlick>

              </GoogleMap>
            ) : <></>}

            {/* </div> */}


            <div>

            </div>

          </div>

          <div className={styles.sideContainer}>

          </div>





        </div>
      </div >

    </>
  )
}

// Notifications.propTypes = {}
// Notifications.defaultProps = {}

export default Map
