import React, { useEffect, useState, useRef } from 'react'
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'
import Input from 'components/Input'
import Button3d from 'components/Button3d'
import styles from './edit.module.scss'
import { images } from 'assets'
import { getDatabase, onValue, ref, update } from 'firebase/database'
import { auth } from 'utils/firebase'
import validate, { tests } from 'utils/validate'
import moment from 'moment'
import Select from 'react-dropdown-select';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
const Edit = () => {
  const history = useHistory();

  const [currentTab, setCurrentTab] = useState('1');
  const [backcolor, setBackcolor] = useState("purple");
  const [input, setInput] = useState({
    email: '',
    firstname: '',
    lastname: '',
    country: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    gender: '',
    dob: '',
    phone: '',
    how: '',
  })
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState({})
  const [resErr, setResError] = useState('')
  const handleOnChange = ({ target: { name, value } }) => {
    setInput((prev) => ({ ...prev, [name]: value }))
    setError((prev) => ({ ...prev, [name]: '' }))
    setResError('')
  }
  const handleOnChangeDob = (value) => {
    setInput((prev) => ({ ...prev, ['dob']: moment(value).format("MM/DD/YYYY") }))
    setError((prev) => ({ ...prev, ['dob']: '' }))
    setResError('')

  }
  const handleOnChangeSelect = (name, value) => {
    console.log(value)
    setInput((prev) => ({ ...prev, [name]: value[0].value }))
    setError((prev) => ({ ...prev, [name]: '' }))
    setResError('')
  }
  const genders = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Other", value: "other" },
    //   { label: "I prefer not to say", value: "i prefer not to say" },
  ];
  const countries = [
    { label: "USA", value: "usa", flag: "🇺🇸" },
    { label: "Jamaica", value: "jamaica", flag: "🇯🇲" },
  ];
  const states = [
    { label: "AL", value: "AL" },
    { label: "AK", value: "AK" },
    { label: "AZ", value: "AZ" },
    { label: "AR", value: "AR" },
    { label: "CA", value: "CA" },
    { label: "CO", value: "CO" },
    { label: "CT", value: "CT" },
    { label: "DE", value: "DE" },
    { label: "FL", value: "FL" },
    { label: "GA", value: "GA" },
    { label: "HI", value: "HI" },
    { label: "ID", value: "ID" },
    { label: "IL", value: "IL" },
    { label: "IN", value: "IN" },
    { label: "IA", value: "IA" },
    { label: "KS", value: "KS" },
    { label: "KY", value: "KY" },
    { label: "LA", value: "LA" },
    { label: "ME", value: "ME" },
    { label: "MD", value: "MD" },
    { label: "MA", value: "MA" },
    { label: "MI", value: "MI" },
    { label: "MN", value: "MN" },
    { label: "MS", value: "MS" },
    { label: "MO", value: "MO" },
    { label: "MT", value: "MT" },
    { label: "NE", value: "NE" },
    { label: "NV", value: "NV" },
    { label: "NH", value: "NH" },
    { label: "NJ", value: "NJ" },
    { label: "NM", value: "NM" },
    { label: "NY", value: "NY" },
    { label: "NC", value: "NC" },
    { label: "ND", value: "ND" },
    { label: "OH", value: "OH" },
    { label: "OK", value: "OK" },
    { label: "OR", value: "OR" },
    { label: "PA", value: "PA" },
    { label: "RI", value: "RI" },
    { label: "SC", value: "SC" },
    { label: "SD", value: "SD" },
    { label: "TN", value: "TN" },
    { label: "TX", value: "TX" },
    { label: "UT", value: "UT" },
    { label: "VT", value: "VT" },
    { label: "VA", value: "VA" },
    { label: "WA", value: "WA" },
    { label: "WV", value: "WV" },
    { label: "WI", value: "WI" },
    { label: "WY", value: "WY" },
  ];

  const db = getDatabase();
  useEffect(() => {
    getProfileInfo()
  }, []);
  const getProfileInfo = () => {
    // Set isLoading to true initially
    setLoading(true);

    const userRef = ref(db, "users/" + auth.currentUser.uid);
    onValue(
      userRef,
      (snapshot) => {
        const data = snapshot.val();
        if (snapshot.val()) {
          console.log(data);
          setInput({
            email: data.email,
            firstname: data.fname,
            lastname: data.lname,
            country: data.country,
            street: data.street,
            city: data.city,
            state: data.state,
            zip: data.zip,
            gender: data.gender,
            dob: data.dob,
            phone: data.phone,
            how: data.referencenote,




          })

          // setEmail(data.email);
          // setFname(data.fname);
          // setLname(data.lname);
          // setCountry(data.country);
          // setStreet(data.street);
          // setCity(data.city);
          // setState(data.state);
          // setZip(data.zip);
          // setGender(data.gender);
          // setDob(new Date(data.dob));
          // setPhone(data.phone);
          // setReferenceNote(data.referencenote);
          // setStreak(data.daysteak.score);
          // setProfilePic(data?.profilePic);
          // setIsMuted(data?.isMuted);
        }

        // Set isLoading to false when the snapshot is done loading
        setLoading(false);
        console.log("here---", data?.isMuted);
      },
      {
        onlyOnce: true,
      }
    );
  };
  const handleUpdate = () => {


    // validation
    const result = validate(input, tests)
    setError(result.errors)
    if (result.isError) return
    setLoading(true);

    console.log(input)

    // if (validation()) {
    try {
      update(ref(db, "users/" + auth.currentUser.uid), {
        fname: input.firstname,
        lname: input.lastname,
        country: input.country,
        street: input.country == 'usa' ? input.street : '',
        city: input.country == 'usa' ? input.city : '',
        state: input.state,
        zip: input.country == 'usa' ? input.zip : '',
        gender: input.gender,
        dob: moment(input.dob).format("MM/DD/YYYY"),
        phone: input.phone,
        referencenote: input.how,


      })
        .then(() => {
          updateProfile(auth.currentUser, {
            displayName: fname + " " + lname,
          })
            .then(() => {
              // Profile updated!
              uploadImage();
              navigation.navigate("Home");
              // ...
              console.log("hellso");
            })
            .catch((error) => {
              setLoading(false);
              console.log("err here", error);
              // An error occurred
              // ...
            });
          setLoading(true);
        })
        .then(() => {
          navigation.navigate("Home");
        })
        .catch((error) => {
          console.log("errer", error.message);
          setLoading(false);
        });
    } catch (error) {
      console.log(auth.currentUser.uid, error);
      setLoading(true);
    }
    // } else {
    //   console.log("hellsos");
    //   setLoading([false, false, false]);
    // }
  };

  const validation = () => {
    const requiredFields = [
      {
        field: fname,
        errorSetter: setFnameError,
        errorMessage: "First Name is required",
      },
      {
        field: lname,
        errorSetter: setLnameError,
        errorMessage: "Last Name is required",
      },
      {
        field: country,
        errorSetter: setCountryError,
        errorMessage: "Country is required",
      },
      {
        field: phone,
        errorSetter: setPhoneError,
        errorMessage: "Phone is required",
      },
    ];

    // Check required fields
    for (const { field, errorSetter, errorMessage } of requiredFields) {
      if (!field) {
        errorSetter(errorMessage);
        return false;
      }
    }

    if (country === "usa") {
      const addressFields = [
        {
          field: street,
          errorSetter: setStreetError,
          errorMessage: "Street is required",
        },
        {
          field: city,
          errorSetter: setCityError,
          errorMessage: "City is required",
        },
        {
          field: state,
          errorSetter: setStateError,
          errorMessage: "State is required",
        },
        {
          field: zip,
          errorSetter: setZipError,
          errorMessage: "Zip is required",
        },
        {
          field:
            moment(dob).format("MM/DD/YYYY") ===
            moment(new Date()).format("MM/DD/YYYY") ||
            moment(dob).format("MM/DD/YYYY") !== "",
          errorSetter: setDobError,
          errorMessage: "Date of Birth is required",
        },
      ];

      // Check address fields and date of birth
      for (const { field, errorSetter, errorMessage } of addressFields) {
        if (!field) {
          errorSetter(errorMessage);
          return false;
        }
      }
    }

    return true;
  };
  return (
    <>
      <div className={styles.root}>
        <div className={styles.container}>

          <div className={styles.sideContainer}>

          </div>

          <div style={{ backgroundSize: 'cover', aspectRatio: '8/16', background: `url(${images.Sunburst}) , linear-gradient(125.07deg, #ff3b86 0%, #9d7fe3 66.18%)` }} className={styles.middleContainer}>



            <div className={styles.menuBar}>

              <div className={styles.width20}>
                <button
                  type="button"
                  style={{ width: '5vh', aspectRatio: '1', alignItems: 'center', flexDirection: 'column', display: 'flex', }}
                  onClick={() => history.goBack()}
                >
                  <div className='image-button-img' style={{ borderRadius: '100%', flex: 3, padding: 3, display: 'flex', width: '100%', backgroundImage: "linear-gradient(#ffe500,#ff7a01)", }}>
                    <div style={{ borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, backgroundColor: `${backcolor}`, backgroundImage: `url(${images.iconBackClear})`, backgroundSize: 'cover', backgroundPosition: 'center', width: '54px' }}>
                    </div>
                  </div>
                </button>
              </div>

            </div>




            <div style={{ marginLeft: '16px', marginRight: '16px', flex: '1', overflowY: 'auto' }}>
              {/* <div className={styles.pageTitle}>
                delete account
              </div> */}
              <div>
                {/* <div className={styles.description}>Please confirm if you are and authorized person in order to delete
                  you account
                </div> */}
                <Input
                  label={true}

                  name="email"
                  placeholder="Email"
                  value={input.email}
                  onChange={handleOnChange}
                  onKeyDown={(e) => { }}
                  error={error.email}
                />
                <Input
                  label={true}

                  name="firstname"
                  placeholder="First Name"
                  value={input.firstname}
                  onChange={handleOnChange}
                  onKeyDown={(e) => { }}
                  error={error.firstname}
                />
                <Input
                  label={true}

                  name="lastname"
                  placeholder="Last Name"
                  value={input.lastname}
                  onChange={handleOnChange}
                  onKeyDown={(e) => { }}
                  error={error.lastname}
                />
                {/* <Input

                  label={true}
                  name="country"
                  placeholder="Country"
                  value={input.country}
                  onChange={handleOnChange}
                  onKeyDown={(e) => { }}
                  error={error.country}
                /> */}
                {input.country &&
                  <div className={`form-group `}>
                    <p className="input-label">Country</p>

                    <div className="input-container">
                      <div className={styles.dropDown} style={{ borderRadius: '50px', flex: 2, padding: 3, display: 'flex', backgroundImage: "linear-gradient(#ffe500,#ff7a01)", height: '50px' }}>
                        <Select
                          values={[countries?.find((item_) => item_.value == input?.country)]}
                          options={countries}
                          onChange={(values) => handleOnChangeSelect("country", values)}
                        />
                      </div>
                    </div>


                  </div>

                }
                {input.country == 'usa' && <Input
                  label={true}

                  name="street"
                  placeholder="Street"
                  value={input.street}
                  onChange={handleOnChange}
                  onKeyDown={(e) => { }}
                  error={error.street}
                />}
                {input.country == 'usa' && <Input
                  label={true}

                  name="city"
                  placeholder="City"
                  value={input.city}
                  onChange={handleOnChange}
                  onKeyDown={(e) => { }}
                  error={error.city}
                />}
                {/* <Input
                  label={true}

                  name="state"
                  placeholder="State"
                  value={input.state}
                  onChange={handleOnChange}
                  onKeyDown={(e) => { }}
                  error={error.state}
                /> */}
                {input?.state &&
                  <div className={`form-group `}>
                    <p className="input-label">State</p>
                    <div className="input-container">
                      <div className={styles.dropDown} style={{ borderRadius: '50px', flex: 2, padding: 3, display: 'flex', backgroundImage: "linear-gradient(#ffe500,#ff7a01)", height: '50px' }}>

                        <Select
                          values={[states?.find((item_) => item_.value == input?.state) ? states?.find((item_) => item_.value == input?.state) : {}]}
                          options={states}
                          onChange={(values) => handleOnChangeSelect("state", values)}
                        />
                      </div>

                    </div>
                  </div>
                }


                {input.country == 'usa' && <Input
                  label={true}

                  name="zip"
                  placeholder="Zip"
                  value={input.zip}
                  onChange={handleOnChange}
                  onKeyDown={(e) => { }}
                  error={error.zip}
                />}
                {/* <Input
                  label={true}

                  name="gender"
                  placeholder="Gender(Optional)"
                  value={input.gender}
                  onChange={handleOnChange}
                  onKeyDown={(e) => { }}
                  error={error.gender}
                /> */}

                {input?.gender &&
                  <div className={`form-group `}>
                    <p className="input-label">Gender(Optional)</p>
                    <div className="input-container">
                      <div className={styles.dropDown} style={{ borderRadius: '50px', flex: 2, padding: 3, display: 'flex', backgroundImage: "linear-gradient(#ffe500,#ff7a01)", height: '50px' }}>

                        <Select
                          values={[genders?.find((item_) => item_.value == input?.gender)]}
                          options={genders}
                          onChange={(values) => handleOnChangeSelect("gender", values)}
                        />
                      </div>
                    </div>
                  </div>
                }
                {/* <div>
                  {JSON.stringify(genders?.find((item_) => item_.value == input?.gender))}
                </div> */}

                {/* <Input
                  label={true}

                  name="dob"
                  placeholder="Date of Birth"
                  value={input.dob}
                  onChange={handleOnChange}
                  onKeyDown={(e) => { }}
                  error={error.dob}
                /> */}
                <div className={`form-group `}>
                  <p className="input-label">Date of Birth</p>
                  <div className="input-container">
                    <div style={{ borderRadius: '50px', flex: 2, padding: 3, display: 'flex', backgroundImage: "linear-gradient(#ffe500,#ff7a01)", height: '50px' }}>
                      <DatePicker
                        error={error.dob}
                        className={`input ${error.dob ? 'form-control is-invalid' : 'form-control'}`}
                        name="dob"
                        selected={input.dob}
                        // onSelect={handleDateSelect} //when day is clicked
                        onChange={handleOnChangeDob} //only when value has changed
                      />

                    </div>
                  </div>
                </div>

                <Input
                  label={true}

                  name="phone"
                  placeholder="Phone"
                  value={input.phone}
                  onChange={handleOnChange}
                  onKeyDown={(e) => { }}
                  error={error.phone}
                />
                <Input
                  label={true}

                  name="how"
                  placeholder="How did you hear about us?"
                  value={input.how}
                  onChange={handleOnChange}
                  onKeyDown={(e) => { }}
                  error={error.how}
                />




                <Button3d
                  label="Update"
                  className={`btn-green`}
                  onClick={() => {




                    // login action



                    handleUpdate()
                  }
                  }
                  isLoading={isLoading}
                />
                <div className={styles.description} style={{ marginTop: 16 }}>{resErr}  </div>
              </div>
            </div>





          </div>

          <div className={styles.sideContainer}>

          </div>





        </div>
      </div >

    </>
  )
}

// Notifications.propTypes = {}
// Notifications.defaultProps = {}

export default Edit
