import React from 'react'

import styles from './VideoModal.module.scss'
import YouTube from 'react-youtube';
import ReactPlayer from 'react-player';

const VideoModal = ({
  image, parentFunction, autoClose, func, url, play
}) => {
  const opts = {
    height: '390',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  return <div style={{ position: 'absolute', zIndex: 1000000, width: '100%', height: '100%' }}>
    <div style={{ backgroundColor: 'transparent', position: 'absolute', zIndex: 1000000, width: '100%', height: '100%' }}></div>

    <div className={styles.wrapper} >
      <div style={{ display: 'flex', flexDirection: 'column', backgroundSize: 'cover', flex: 1, backgroundColor: 'black', justifyContent: 'center', alignItems: 'center' }}>


        {/* <img style={{ width: '100%' }} src={image} /> */}

        {/* <YouTube videoId={url} opts={opts} onEnd={func} /> */}
        <ReactPlayer config={{
          youtube: {
            playerVars: { showinfo: 0 }
          },

        }}
          playing={true}
          // muted={true}
          width={"100%"}
          url={'https://www.youtube.com/watch?v=' + url}
          onEnded={func}
        />


      </div>
    </div>
  </div>
}



export default VideoModal
