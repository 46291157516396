import React from 'react'
import PropTypes from 'prop-types'

import Spinner from '../../components/Spinner'
import { images } from 'theme'
import styles from './TdButton.module.scss'
import audio from '../../assets/sounds/click_bright.mp3'
const TdButton = ({
  back,
  className,
  style,
  label,
  onClick,
  isLoading,
  disabled,
  children,
  image,
  flex = [4, 1],
  aspectRatio = '1',
  ...others
}) => {
  const action = () => {
    new Audio(audio).play();
    onClick()
  }
  return <div style={{ aspectRatio: aspectRatio, display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>


    <button type="button" className={styles.button_} onClick={() => action()}>
      <div className={styles.buttonTop} style={{ aspectRatio: aspectRatio }}>
        {isLoading ? (
          <Spinner color="white" isLoading />
        ) : (
          <>
            {/* <img style={{ width: '100%', zIndex: 1000 }} src={image} /> */}
            <div style={{ zIndex: 10000, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', width: '100%', height: '100%', backgroundImage: `url(${image})` }}>

            </div>
          </>
        )}

      </div>
      <div className={styles.buttonBottom}></div>
      <div className={styles.buttonBase}></div>

    </button>
    <div className={styles.buttonText} style={{ flex: flex[1], fontFamily: 'BebasNeue', color: 'white', fontSize: '2.5vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {label}
    </div>
  </div>
}

// ImageButton.propTypes = {
//   label: PropTypes.string,
//   className: PropTypes.string,
//   style: PropTypes.objectOf(PropTypes.object),
//   children: PropTypes.node,
//   onClick: PropTypes.func,
//   disabled: PropTypes.bool,
//   isLoading: PropTypes.bool,
// }

// ImageButton.defaultProps = {
//   label: '',
//   className: '',
//   style: {},
//   onClick: () => { },
//   children: null,
//   disabled: false,
//   isLoading: false,
// }

export default TdButton
