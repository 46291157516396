import React, { useEffect, useState, useRef } from 'react'
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'
import Input from 'components/Input'
import Button3d from 'components/Button3d'
import styles from './delete.module.scss'
import { images } from 'assets'
import { auth } from 'utils/firebase'
import { signInWithEmailAndPassword, signOut, deleteUser } from "firebase/auth";
import {
  getDatabase,
  onValue,
  ref,
  query,
  orderByChild,
  equalTo,
  set
} from "firebase/database";
import GameModal from 'pageComponents/GameModal'
import validate, { tests } from 'utils/validate'
const Delete = () => {
  const history = useHistory();

  const [currentTab, setCurrentTab] = useState('1');
  const [backcolor, setBackcolor] = useState("purple");

  const [input, setInput] = useState({
    email: '',
    password: '',
  })
  const [error, setError] = useState({})
  const [resErr, setResError] = useState('')
  const [openGameModal, setOpenGameModal] = useState(false);
  const [isLoading, setLoading] = useState(false)
  const modalContent = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>


        <div style={{ display: 'flex', gap: 16, flexDirection: 'column' }}>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              WARNING
            </div>
            <div className={styles.cardDescription}>
              You are about to delete your account. By doing this
              you will loose your progress.

            </div>
          </div>

        </div>
        <div style={{ width: '100%', marginTop: 16, alignSelf: 'center', display: 'flex', justifyContent: 'space-between' }}>

          <Button3d style={{ width: '48%' }} className={`btn-green`} label={'go back'} onClick={() => setOpenGameModal(false)} ></Button3d>
          <Button3d style={{ width: '48%' }} className={`btn-red`} label={'Delete'} onClick={() => { handleDelete() }}></Button3d>
        </div>

      </div>
    )
  }
  const handleOnChange = ({ target: { name, value } }) => {
    setInput((prev) => ({ ...prev, [name]: value }))
    setError((prev) => ({ ...prev, [name]: '' }))
    setResError('')
  }
  const handleDelete = () => {
    const db = getDatabase()
    setLoading(true);
    const { email, password } = input;

    const userRef = query(
      ref(db, "users/"),
      orderByChild("email"),
      equalTo(email)
    );

    onValue(userRef, (snapshot) => {
      if (snapshot.exists()) {
        let keys = Object.keys(snapshot.val());

        if (snapshot.val()[keys[0]].type == "player") {
          signInWithEmailAndPassword(auth, email, password)
            .then((data) => {
              let id = auth.currentUser.uid;
              console.log(id)

              set(ref(db, "users/" + id), null).then(() => {
                deleteUser(auth.currentUser)
                  .then(() => {
                    setOpenGameModal(false)
                    setResError("Account deleted");
                    console.log("deleted")
                    // User deleted.
                    setLoading(false);
                  })
                  .catch((error) => {
                    console.log(error)
                    // An error ocurred
                    // ...
                  });
              });
            })
            .catch((error) => {
              setOpenGameModal(false)
              setResError(error.code);
              setLoading(false)
            });
        } else {
          setOpenGameModal(false)
          console.log("User not found")
          setResError("User not found");
          setLoading(false)
        }
      } else {
        setOpenGameModal(false)
        console.log("User not found")
        setResError("User not found");
        setLoading(false);
      }
    });
  };
  return (
    <>
      <div className={styles.root}>
        <div className={styles.container}>

          <div className={styles.sideContainer}>

          </div>

          <div style={{ backgroundSize: 'cover', aspectRatio: '8/16', background: `url(${images.Sunburst}) , linear-gradient(125.07deg, #ff3b86 0%, #9d7fe3 66.18%)` }} className={styles.middleContainer}>

            {
              openGameModal && <GameModal onClick={() => setOpenGameModal(false)} content={modalContent()} ></GameModal>
            }


            <div className={styles.menuBar}>

              <div className={styles.width20}>
                <button
                  type="button"
                  style={{ width: '5vh', aspectRatio: '1', alignItems: 'center', flexDirection: 'column', display: 'flex', }}
                  onClick={() => history.goBack()}
                >
                  <div className='image-button-img' style={{ borderRadius: '100%', flex: 3, padding: 3, display: 'flex', width: '100%', backgroundImage: "linear-gradient(#ffe500,#ff7a01)", }}>
                    <div style={{ borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, backgroundColor: `${backcolor}`, backgroundImage: `url(${images.iconBackClear})`, backgroundSize: 'cover', backgroundPosition: 'center', width: '54px' }}>
                    </div>
                  </div>
                </button>
              </div>

            </div>


            <div style={{ marginLeft: '16px', marginRight: '16px', flex: '1' }}>
              <div className={styles.pageTitle}>
                delete account
              </div>
              <div>
                <div className={styles.description}>Please confirm if you are and authorized person in order to delete
                  you account
                </div>
                <Input
                  name="email"
                  placeholder="Email"
                  value={input.email}
                  onChange={handleOnChange}
                  onKeyDown={(e) => { }}
                  error={error.email}
                />
                <Input
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={input.password}
                  onChange={handleOnChange}
                  onKeyDown={(e) => { }}
                  error={error.password}
                />
                <Button3d
                  label="Delete"
                  className={`btn-red`}
                  onClick={() => {



                    // validation
                    const result = validate(input, tests)
                    setError(result.errors)
                    if (result.isError) return

                    // login action



                    setOpenGameModal(true)
                  }
                  }
                  isLoading={isLoading}
                />
                <div className={styles.description} style={{ marginTop: 16 }}>{resErr}  </div>
              </div>
            </div>

          </div>

          <div className={styles.sideContainer}>

          </div>





        </div>
      </div >

    </>
  )
}

// Notifications.propTypes = {}
// Notifications.defaultProps = {}

export default Delete
