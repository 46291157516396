import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/Modal'
import FontIcon from 'components/FontIcon'
import Button from 'components/Button'
import { useHistory } from 'react-router-dom'
import styles from './ProfileActionButton.module.scss'
import { path } from 'utils/const'
import { images } from 'assets'

const ProfileActionButton = ({
  onPress,
  imageName,
  title,
  btnSource,

}) => {
  return <div type="button" onClick={() => onPress()}>
    <div className={styles.btnAction} style={{
      background: `url(${btnSource})`,
      width: '100%',
      // height: '70px',
      aspectRatio: '4',
      display: 'flex',
      alignItems: 'center',
      // justifyContent: 'center'
    }}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '30px'
      }}>
        <div>
          <img style={{
            width: '40px',
            height: '40px'
          }} src={imageName}></img>
        </div>
        <div className={styles.title}>
          {title}
        </div>
      </div>
    </div>
  </div>
}







export default ProfileActionButton
