import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from 'components/Button'
import { actions } from 'slices/app.slice'
import { images } from 'theme'
import moment from 'moment'
import {
  getDatabase,
  ref,
  onValue,

} from "firebase/database";
import { useHistory, useLocation } from 'react-router-dom'
import styles from './locations.module.scss'
import LiveBingoListItem from 'pageComponents/LiveBingoListItem'
import ImageButton from 'pageComponents/ImageButton/ImageButton';
import LocationCard from 'pageComponents/LocationCard';
import Spinner from 'components/Spinner';
import GameModal from 'pageComponents/GameModal';
import Input from 'components/Input'
import Button3d from 'components/Button3d'
import { path } from 'utils/const'
import TdButton from 'pageComponents/3dButton/TdButton'
import { doc, getDoc, getFirestore } from 'firebase/firestore'
var CryptoJS = require("crypto-js");
// const category = 1
const Locations = () => {
  const { search } = useLocation();
  const db = getDatabase();
  const fsdb = getFirestore();
  const history = useHistory()
  const dispatch = useDispatch()
  const { me } = useSelector((state) => state.app)
  const [liveShows, setLiveShows] = useState([]);
  const [liveShowsOriginal, setLiveShowsOriginal] = useState([]);
  const [showList, setShowList] = useState(true);
  const [openGameModal, setOpenGameModal] = useState(false);
  const [openGameModalAlert, setOpenGameModalAlert] = useState(false);
  const [type, setType] = useState('');

  // const windowRef = useRef(null);
  const [width, setWidth] = useState(0)
  const [locationRef, setLocationRef] = useState("");
  const [roomPass, setRoomPass] = useState("");
  const [roomPassMaster, setRoomPassMaster] = useState("");
  const [roomPassGeneral, setRoomPassGeneral] = useState("");
  const [inputError, setInputError] = useState("");
  const [backcolor, setBackcolor] = useState("purple");
  const [noActiveShows, setNoActiveShows] = useState(false);


  const getCategory = () => {
    const searchParams = new URLSearchParams(search);
    const param = searchParams.get("t");
    return param
  }

  const modalContentAlert = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>


        <div style={{ display: 'flex', gap: 16, flexDirection: 'column' }}>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              ALERT
            </div>
            <div className={styles.cardDescription}>
              The room limit maximum has been reached.

            </div>
          </div>

        </div>
        <div style={{ width: '100%', marginTop: 16, alignSelf: 'center', display: 'flex', justifyContent: 'center' }}>


          <Button3d style={{ width: '50%' }} className={`btn-green`} label={'Ok'} onClick={() => { setOpenGameModalAlert(false) }}></Button3d>
        </div>

      </div>
    )
  }
  useEffect(() => {
    // setWindowHeight(windowRef.current.clientHeight)
    // setWidth(windowRef.current.clientHeight / 16 * 8 / 5)

    let date2 = moment(me.dob);
    let date1 = moment(new Date());
    let days = date1.diff(date2, "days");

    if (days < 365 * 21 && getCategory() != 2) {
      setShowList(false);
    }


    const db = getDatabase();

    const userSettingRef = ref(
      db,
      "/users/" + me.id + "/lastsession"
    );
    console.log(me.id)
    onValue(userSettingRef, (snapshot) => {
      let data_ = null;
      if (snapshot.exists()) {
        data_ = snapshot.val()
        // lastsession.current = data


      }



      const liveref = ref(db, "groupsbycategory/" + getCategory());
      onValue(liveref, (snapshot) => {
        let tempArray = [];

        snapshot.forEach(function (item) {
          let locations = item.val().locations;

          const locationKeys = Object.keys(locations);
          locationKeys.forEach((key, index) => {

            let d = null
            //  locations[key]['userlastsession'] = data_
            if (
              locations[key].country &&
                locations[key].country.val == me?.country?.value
                ? me?.country?.value
                : me?.country && locations[key].intday == new Date().getDay()
            ) {
              if (locations[key].istesting == false) {
                locations[key].groupid = item.key;
                locations[key].locationid = key;
                d = locations[key]
                // tempArray.push(locations[key]);
              } else if (
                locations[key].istesting == true &&
                me.istesting == true
              ) {
                locations[key].groupid = item.key;
                locations[key].locationid = key;
                d = locations[key]
                // tempArray.push(locations[key]);

              }


              if (d != null) {

                d['userlastsession'] = data_
              }
              if (d && d.intday == new Date().getDay()) {
                tempArray.push(d)
              }
            }
          });
        });


        tempArray = tempArray.sort((a, b) => b.opened - a.opened);
        if (tempArray.length == 0) {
          setNoActiveShows(true)
        }

        // console.log(tempArray);
        setLiveShowsOriginal(tempArray);
        setLiveShows(tempArray);
        // updateData()
      });



    }, {
      onlyOnce: true
    });





  }, []);
  const Encrypt = (word, key = 'share') => {
    let encJson = CryptoJS.AES.encrypt(JSON.stringify(word), key).toString()
    let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson))
    return encData
  }
  const joinRoom = (allowjoin) => {




    if (roomPass != "") {
      setInputError("");
      if (roomPass == roomPassMaster || roomPass == roomPassGeneral) {
        setOpenGameModal(false);
        history.push(path.game + "?code=" + Encrypt(locationRef))
      } else {
        // alert("Password Incorrect");
        setInputError("Password Incorrect");
      }
    } else {
      setInputError("Please enter password first");
    }



  }
  const modalContent = () => {
    return (
      <>
        <div style={{ textAlign: 'center' }}>
          <div>
            <div className={styles.modalTitle}>ROOM PASSWORD</div>
          </div>
          <div>
            <Input className={styles.inputClass} name="password"
              placeholder="Enter Password"
              onChange={e => setRoomPass(e.target.value)}
              value={roomPass}
              type="password"
            ></Input>
          </div>
          {/* <div className={styles.radioBtns} onChange={() => setType(this)}>
            <input type="radio" value="player" name="type" /> Player
            <div style={{ width: '16px' }}></div>
            <input type="radio" value="host" name="type" /> Host
          </div> */}
          <div className={styles.btnComponent}>
            <div style={{ display: 'flex', gap: 16 }}>
              <Button3d className={`btn-red`} label={'Cancel'} onClick={() => setOpenGameModal(false)} ></Button3d>

              <Button3d className={`btn-green`} label={'Join'} onClick={() => joinRoom()}></Button3d>
            </div>

            {inputError && <div style={{ color: '#fff' }}>{inputError}</div>}
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className={styles.root}>
        <div className={styles.container}>

          <div className={styles.sideContainer}>

          </div>

          <div style={{ backgroundSize: 'cover', aspectRatio: '8/16', background: `url(${images.Sunburst}) , linear-gradient(125.07deg, #ff3b86 0%, #9d7fe3 66.18%)` }} className={styles.middleContainer}>

            {
              openGameModalAlert && <GameModal onClick={() => setOpenGameModalAlert(false)} content={modalContentAlert()} ></GameModal>
            }


            <div className={styles.menuBar}>

              <div className={styles.width20}>
                <button
                  type="button"
                  style={{ width: '5vh', aspectRatio: '1', alignItems: 'center', flexDirection: 'column', display: 'flex', }}
                  onClick={() => history.goBack()}
                >
                  <div className='image-button-img' style={{ borderRadius: '100%', flex: 3, padding: 3, display: 'flex', width: '100%', backgroundImage: "linear-gradient(#ffe500,#ff7a01)", }}>
                    <div style={{ borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, backgroundColor: `${backcolor}`, backgroundImage: `url(${images.iconBackClear})`, backgroundSize: 'cover', backgroundPosition: 'center', width: '54px' }}>
                    </div>
                  </div>
                </button>
              </div>
              <div className={styles.logoOld}></div>
              <div className={styles.width20}>
                <TdButton
                  back={''}
                  label="Map View"
                  image={images.globe}
                  onClick={() => history.push(path.map)}
                />
              </div>
            </div>
            <div className={styles.topRow}>

              <div className={styles.whiteLetters}>PLAY LIVE, IN PERSON BINGO ON YOUR PHONE !</div>
              <div style={{ width: '100%', padding: '0 16px' }}>
                {/* search bar gose here */}
                <Input placeholder={'Enter your zip code'} iconName={'search'}></Input>
              </div>
            </div>
            {
              openGameModal && <GameModal onClick={() => setOpenGameModal(false)} content={modalContent()} ></GameModal>
            }

            <div className={styles.middleRow}>
              {!noActiveShows && liveShows.length == 0 && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>

                <Spinner color="white" isLoading={liveShows.length == 0} />
              </div>}


              {
                noActiveShows && <div style={{ textAlign: 'center', color: '#ffffff7d' }}>No active shows</div>
              }
              {
                showList && liveShows.map(function (item, i) {

                  return <LocationCard key={i}

                    dateobject={{
                      date: item.intday,
                      time: item.time,
                    }}

                    // distance={{ text: item.distancetext, value: item.distancevalue }}
                    locationid={item.locationid}
                    location={item.name}
                    userlastsession={item.userlastsession}
                    status={item.status}
                    btnEnabled={item.opened}
                    address={item.address}
                    address2={item.city +
                      ", " +
                      item.state +
                      ", " +
                      item.zip}
                    phone={item.phone}
                    schedule={item.day + "s" + " at " + moment(item.time).format("hh:mm A")
                    }

                    pic={item.imageurl}
                    locationRef={
                      "/groupsbycategory/" +
                      getCategory() +
                      "/" +
                      item.groupid +
                      "/locations/" +
                      item.locationid
                    }
                    onClick={async () => {


                      let cnt = 0
                      const docRef = doc(fsdb, "gamesessions", item.status.sessionno);
                      const docSnap = await getDoc(docRef);
                      if (docSnap.exists()) {
                        cnt = Number(docSnap?.data().onlinecount) || 0

                        if (cnt < item.playerlimit || item.playerlimit == null) {
                          setLocationRef("/groupsbycategory/" +
                            getCategory() +
                            "/" +
                            item.groupid +
                            "/locations/" +
                            item.locationid)

                          if (getCategory() == 2) {
                            history.push(path.game + "?code=" + Encrypt("/groupsbycategory/" +
                              getCategory() +
                              "/" +
                              item.groupid +
                              "/locations/" +
                              item.locationid))
                          }
                          else {
                            const passwordRef = ref(db, "/groupsbycategory/" +
                              getCategory() +
                              "/" +
                              item.groupid +
                              "/locations/" +
                              item.locationid + "/roompassword");
                            onValue(
                              passwordRef,
                              (snapshot) => {
                                if (snapshot.exists()) {
                                  const spasswordRef = ref(db, "config/superadminpassword");
                                  onValue(
                                    spasswordRef,
                                    (snapshot_) => {
                                      if (snapshot_.exists()) {
                                        setOpenGameModal(true)
                                        setInputError("");
                                        setRoomPassMaster(snapshot.val());
                                        setRoomPassGeneral(snapshot_.val());
                                      }
                                    },
                                    {
                                      onlyOnce: true,
                                    }
                                  );

                                  // setShowModal(true);
                                  // setInputError("");
                                  // setRoomPass(snapshot.val());
                                } else {
                                  // no password
                                }
                              },
                              {
                                onlyOnce: true,
                              }
                            );

                          }



                        }
                        else {
                          setOpenGameModalAlert(true)
                          // alert("limit exceed")
                        }


                      }






                    }
                    }
                  />


                  // return <LiveBingoListItem
                  //   token={'token'}
                  //   locationRef={
                  //     "/groupsbycategory/" +
                  //     category +
                  //     "/" +
                  //     item.groupid +
                  //     "/locations/" +
                  //     item.locationid
                  //   }
                  //   category={category}
                  //   islogoshow={item.isshowlogo}
                  //   locationid={item.locationid}
                  //   status={item.status}
                  //   titlemain={item.name}
                  //   pic={item.imageurl}
                  //   address={item.address}
                  //   address2={
                  //     item.city +
                  //     ", " +
                  //     item.state +
                  //     ", " +
                  //     item.zip
                  //   }
                  //   phone={item.phone}
                  //   dateobject={{
                  //     date: item.intday,
                  //     time: item.time,
                  //   }}
                  //   schedule={
                  //     item.day +
                  //     "s" +
                  //     " at " +
                  //     moment(item.time).format("hh:mm A")
                  //   }
                  //   btnEnabled={item.opened}
                  //   btnDisabledText={
                  //     "Room Opens @ " +
                  //     item.day +
                  //     " " +
                  //     moment(item.time).format("hh:mm A")
                  //   }
                  //   user_={me}
                  // />
                })
              }
            </div>
            <div className={styles.bottomRow}>

            </div>

          </div>

          <div className={styles.sideContainer}>

          </div>





        </div>
      </div >

    </>
  )
}

// Notifications.propTypes = {}
// Notifications.defaultProps = {}

export default Locations
