import { createSlice } from '@reduxjs/toolkit'
import { equalTo, getDatabase, onValue, orderByChild, query, ref, set } from 'firebase/database'
import { firestore, auth } from 'utils/firebase'
import { v4 as uuidv4 } from 'uuid';
// ------------------------------------
// State
// ------------------------------------

const initialState = {
  checked: false,
  loggedIn: false,
  me: {},
}

// ------------------------------------
// Slices
// -----------------------------------

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setMe: (state, action) => ({
      ...state,
      me: action.payload.me,
      loggedIn: action.payload.loggedIn,
      checked: true,
    }),
    setLoggedIn: (state, action) => ({
      ...state,
      loggedIn: action.payload,
    }),
  },
})

// ------------------------------------
// Actions
// -----------------------------------

export const authenticate = () => (dispatch) => {
  auth.onAuthStateChanged(async (me) => {
    if (!me) {
      return dispatch(
        slice.actions.setMe({
          loggedIn: false,
          checked: true,
          me: {},
        }),
      )
    }

    // get user from firestore
    // const user = await firestore.collection('users').doc(me?.uid).get()÷
    const db = getDatabase();

    const userRef = query(ref(db, 'users/'), orderByChild('email'), equalTo(me?.email.toLowerCase()));
    onValue(userRef, (snapshot) => {
      let user = null
      if (snapshot.exists()) {

        let keys = []
        keys = Object.keys(snapshot.val())
        user = snapshot.val()[keys[0]]

        // setUser(snapshot.val()[keys[0]])
        // setUserID(keys[0])
        // console.log(keys[0])
        let uuid = uuidv4()
        localStorage.setItem("@uuid", uuid);
        set(ref(db, "users/" + me?.uid + "/uuid/"), {
          uuid: uuid,

        });
        if (snapshot.val()[keys[0]].type === "player") {

          return dispatch(
            // slice.actions.setMe({
            //   loggedIn: me?.emailVerified && user.exists,
            //   me: user.exists
            //     ? { id: me?.uid, emailVerified: me?.emailVerified, ...user.data() }
            //     : {},
            //   checked: true,
            // }),

            slice.actions.setMe({
              loggedIn: true,
              me: user
                ? { id: me?.uid, emailVerified: true, ...user }
                : {},
              checked: true,
            }),
          )
        }
        else {

          console.log("user not found")
          // isComponentMounted.current && setErrorState('User not found');
          // isComponentMounted.current && setLoading(false);
        }
      } else {
        console.log("user not found")
        // isComponentMounted.current && setErrorState('User not found');
        // isComponentMounted.current && setLoading(false);
      }
      return null
    }, {
      onlyOnce: true
    });







    // login
    // return dispatch(
    //   // slice.actions.setMe({
    //   //   loggedIn: me?.emailVerified && user.exists,
    //   //   me: user.exists
    //   //     ? { id: me?.uid, emailVerified: me?.emailVerified, ...user.data() }
    //   //     : {},
    //   //   checked: true,
    //   // }),
    //   slice.actions.setMe({
    //     loggedIn: true,
    //     me: user.exists
    //       ? { id: me?.uid, emailVerified: true, ...user.data() }
    //       : {},
    //     checked: true,
    //   }),
    // )
    return null
  })
}

const signup = ({ fullName, email, password }) => () =>
  new Promise(async (resolve, reject) => {
    try {
      // create user
      const { user } = await auth.createUserWithEmailAndPassword(
        email,
        password,
      )

      // send confirmation email
      await user.sendEmailVerification()

      // store user info in firestore
      await firestore.collection('users').doc(user.uid).set({
        fullName,
        email,
      })

      resolve(user)
    } catch (err) {
      reject(err)
    }
  })

const login = ({ email, password }) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const { user } = await auth.signInWithEmailAndPassword(email, password)
      if (!user) reject(new Error('Failed to login. please try it again later'))
      if (!user.emailVerified) await user.sendEmailVerification()

      dispatch(authenticate())
      resolve(user)
    } catch (err) {
      reject(err)
    }
  })

const logout = () => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      await auth.signOut()
      dispatch(
        slice.actions.setMe({
          checked: true,
          loggedIn: false,
          me: {},
        }),
      )
      resolve()
    } catch (err) {
      reject(err)
    }
  })

const resetPassword = (email) => () => auth.sendPasswordResetEmail(email)

// ------------------------------------
// Exports
// ------------------------------------

export const actions = {
  ...slice.actions,
  authenticate,
  signup,
  login,
  logout,
  resetPassword,
}

export default slice.reducer
