import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/Modal'
import FontIcon from 'components/FontIcon'
import Button from 'components/Button'
import { useHistory } from 'react-router-dom'
import styles from './CardButton.module.scss'
import { path } from 'utils/const'
import { images } from 'assets'

const CardButton = ({ tileSize,
  onPress,
  item,
  index,
  winpattern,
  color,
  gameno,
  stampColor,
  rotation,
  url,
  category,
  currentGameName,
  size,
  back,
  isWinPattern

}) => {

  const shades = {

    "A": [2, 6, 8, 10, 11, 12, 13, 14, 15, 19, 20, 24],
    "ARROW": [2, 6, 7, 8, 10, 11, 12, 13, 14, 17, 22],
    "B": [0, 1, 2, 3, 5, 9, 10, 11, 12, 13, 15, 19, 20, 21, 22, 23],
    "BIN": [0, 1, 2, 5, 6, 7, 10, 11, 12, 15, 16, 17, 20, 21, 22],
    "BOTTOM-T": [2, 7, 12, 17, 20, 21, 22, 23, 24],
    "BOWTIE": [0, 4, 5, 6, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 19, 20, 24],
    "C-PATTERN": [0, 1, 2, 3, 4, 5, 10, 15, 20, 21, 22, 23, 24],
    "CACTUS": [0, 2, 4, 5, 7, 9, 10, 11, 12, 13, 14, 17, 22],
    "CHAIR": [0, 5, 10, 11, 12, 13, 14, 15, 19, 20, 24],
    "CHECKER-BOARD": [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24],
    "COVERALL": [],
    "CROSS": [2, 5, 6, 7, 8, 9, 12, 17, 22],
    "DIAMOND": [2, 6, 8, 10, 14, 16, 18, 22],
    "DOG-BONE": [5, 9, 10, 11, 12, 13, 14, 15, 19],
    "E": [0, 1, 2, 3, 4, 5, 10, 11, 12, 13, 14, 15, 20, 21, 22, 23, 24],
    "F": [0, 1, 2, 3, 4, 5, 10, 11, 12, 13, 15, 20],
    "FLAG": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 20],
    "FOUR-BRACKETS": [0, 1, 3, 4, 5, 9, 15, 19, 20, 21, 23, 24],
    "FOUR-CLOVERBOARD": [0, 1, 3, 4, 5, 6, 8, 9, 12, 15, 16, 18, 19, 20, 21, 23, 24],
    "FOUR-CORNERS": [0, 4, 20, 24],
    "FOUR-PATTERN": [0, 4, 5, 9, 10, 11, 12, 13, 14, 19, 24],
    "FULL-DIAMOND": [2, 6, 7, 8, 10, 11, 12, 13, 14, 16, 17, 18, 22],
    "G": [0, 1, 2, 3, 4, 5, 10, 12, 13, 14, 15, 19, 20, 21, 22, 23, 24],
    "H": [0, 4, 5, 9, 10, 11, 12, 13, 14, 15, 19, 20, 24],
    "HAT": [6, 7, 8, 11, 12, 13, 15, 16, 17, 18, 19],
    "HEART": [1, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 16, 17, 18, 22],
    "HIGHWAY": [0, 1, 3, 4, 5, 6, 8, 9, 10, 11, 13, 14, 15, 16, 18, 19, 20, 21, 23, 24],
    "HOURGLASS": [0, 1, 2, 3, 4, 6, 7, 8, 12, 16, 17, 18, 20, 21, 22, 23, 24],
    "I": [0, 1, 2, 3, 4, 7, 12, 17, 20, 21, 22, 23, 24],
    "KITE": [0, 1, 5, 6, 12, 18, 24],
    "K": [0, 4, 5, 8, 10, 11, 12, 15, 18, 20, 24],
    "LAYERS": [0, 1, 2, 3, 4, 10, 11, 12, 13, 14, 20, 21, 22, 23, 24],
    "L": [0, 5, 10, 15, 20, 21, 22, 23, 24],
    "LADDER": [0, 4, 5, 6, 7, 8, 9, 10, 14, 15, 16, 17, 18, 19, 20, 24],
    "LUCKY-": [0, 1, 2, 3, 4, 8, 12, 16, 20],
    "MAPLE-LEAF": [2, 5, 7, 9, 11, 12, 13, 15, 16, 17, 18, 19, 22],
    "MARTINI-GLASS": [0, 4, 6, 8, 12, 17, 20, 21, 22, 23, 24],
    "N": [0, 4, 5, 6, 9, 10, 12, 14, 15, 18, 19, 20, 24],
    "NGO": [2, 3, 4, 7, 8, 9, 12, 13, 14, 17, 18, 19, 22, 23, 24],
    "NO-SIGN": [1, 2, 3, 5, 8, 9, 10, 12, 14, 15, 16, 19, 21, 22, 23],
    "O": [1, 2, 3, 5, 9, 10, 14, 15, 19, 21, 22, 23],
    "P": [0, 1, 2, 3, 4, 5, 9, 10, 11, 12, 13, 14, 15, 20],
    "PICTURE-FRAME": [0, 1, 2, 3, 4, 5, 9, 10, 14, 15, 9, 20, 21, 22, 23, 24],
    "PLUS-SIGN": [2, 7, 10, 11, 12, 13, 14, 17, 22],
    "RAILROAD": [1, 3, 6, 8, 11, 13, 16, 18, 21, 23],
    "REGULAR": [],
    "S": [0, 1, 2, 3, 4, 5, 10, 11, 12, 13, 14, 19, 20, 21, 22, 23, 24],
    "SMALL-FRAME": [6, 7, 8, 11, 13, 16, 17, 18],
    "SMILEY-FACE": [6, 8, 15, 19, 21, 22, 23],
    "SPUTNIK": [0, 4, 6, 7, 8, 11, 12, 13, 16, 17, 18, 20, 24],
    "STAMP": [3, 4, 8, 9],
    "T": [0, 1, 2, 3, 4, 7, 17, 22],
    "TABLE": [5, 6, 7, 8, 9, 12, 16, 18, 20, 24],
    "THIRTEEN-PATTERN": [0, 2, 3, 4, 5, 9, 10, 12, 13, 14, 15, 19, 20, 22, 23, 24],
    "TIC-TAC-TOE": [1, 3, 5, 6, 7, 8, 9, 11, 13, 15, 16, 17, 18, 19, 21, 23],
    "USA-FLAG": [0, 1, 5, 6],
    "V": [0, 4, 5, 9, 10, 14, 16, 18, 22],
    "X": [0, 4, 6, 8, 12, 16, 18, 20, 24],
    "Y": [0, 4, 6, 8, 12, 17, 22],
    "Z": [0, 1, 2, 3, 4, 8, 12, 16, 20, 21, 22, 23, 24],
    "D": [0, 1, 2, 3, 5, 9, 10, 14, 15, 19, 20, 21, 22, 23],
    "J": [0, 1, 2, 3, 4, 8, 13, 15, 18, 20, 21, 22, 23],
    "M": [0, 4, 5, 6, 8, 9, 10, 12, 14, 15, 19, 20, 24],
    "Q": [0, 1, 2, 3, 4, 5, 9, 10, 14, 15, 18, 19, 20, 21, 22, 23, 24],
    "R": [0, 1, 2, 3, 4, 5, 9, 10, 11, 12, 13, 14, 15, 18, 20, 24],
    "U": [0, 4, 5, 9, 10, 14, 15, 19, 20, 21, 22, 23, 24],
    "W": [0, 4, 5, 9, 10, 14, 15, 17, 19, 20, 21, 23, 24],
    "T-PATTERN": [0, 1, 2, 3, 4, 7, 17, 22],
  }
  const shadesSecondary = {
    "REGULAR": [],
    "REGULAR": [],
    "REGULAR": [],
    "FOUR-CORNERS": [6, 8, 16, 18],
    "C-PATTERN": [],
    "REGULAR": [],
    "REGULAR": [],
    "REGULAR": [],
    "T-PATTERN": [],
    "COVERALL": [],
    "USA-FLAG": [2, 3, 4, 10, 11, 12, 13, 14, 20, 21, 22, 23, 24],
  };

  if (isWinPattern) {
    return <div className={styles.wrapper} style={{


      backgroundColor: item.selected ? back : index == 12 ? back : null,


      color: index == 12 ? 'white' : null
    }}>
      <div style={{ flex: 1, fontSize: index == 12 ? '3vh' : '5vh', fontFamily: 'BebasNeue', zIndex: 1000, color: item.selected ? 'white' : null }}>

        {item.no}
      </div>


    </div>
  }
  else {
    return <div className={styles.wrapper} style={{

      backgroundColor: index == 12 ? back : shades[currentGameName]?.includes(index)
        ? "#509ce650"
        : shadesSecondary[currentGameName]?.includes(index)
          ? "#b7124a50"
          : null,




      color: index == 12 ? 'white' : null
    }}>
      <div style={{ flex: 1, fontSize: index == 12 ? '3vh' : '5vh', fontFamily: 'BebasNeue', zIndex: 1000, color: item.selected ? 'white' : null }}>

        {item.no}
      </div>
      {item.selected && !isWinPattern && <div style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,


      }}>
        <MyLogo back={back} />
      </div>}

    </div>
  }






}

// SentResetPassword.propTypes = {
//   email: PropTypes.string,
//   isOpen: PropTypes.bool,
//   toggle: PropTypes.func,
//   onSubmit: PropTypes.func,
// }

// SentResetPassword.defaultProps = {
//   email: '',
//   isOpen: false,
//   toggle: () => {},
//   onSubmit: () => {},
// }
function MyLogo({ back }) {
  // 👇️ paste SVG into a component
  // take fill and stroke colors as props
  return (
    <div style={{ width: '100%', height: '100%', backgroundColor: `${back}`, maskImage: `url(${images.inkspot})`, maskSize: 'contain' }}>



    </div>
  );
}
export default CardButton
