import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/Modal'
import FontIcon from 'components/FontIcon'
import Button from 'components/Button'
import { useHistory } from 'react-router-dom'
import styles from './ShowAll.module.scss'
import { path } from 'utils/const'
import { images } from 'assets'
var CryptoJS = require("crypto-js");
const ShowAll = ({
  drawnNumbers,
  backcolor,
  back,
  onClick, category,
  loteria
}) => {
  const wordBingo = ["B", "I", "N", "G", "O"]
  //Game Ball Images
  const gameBallImages = [
    images.blueBall,
    images.redBall,
    images.greyBall,
    images.greenBall,
    images.yellowBall,
  ];
  const gameBallImagesL = [
    images.one,
    images.two,
    images.three,
    images.four,
    images.five,
  ];
  const numbers = Array.from({ length: 75 }, (_, i) => i + 1)
  console.log(drawnNumbers)
  // alert(JSON.stringify(groupedData))
  return <div className={styles.wrapper} style={{
    background: `url(${back}) rgba(0, 0, 0, 0.5)`, backgroundSize: 'cover',
    backgroundBlendMode: 'multiply', backgroundSize: 'cover'
  }}>
    <div class="" style={{ display: 'flex', flexDirection: 'column', backgroundSize: 'cover', flex: 1 }}>

      {/* <button
        type="button"
        style={{ width: '5vh', aspectRatio: '1', alignItems: 'center', flexDirection: 'column', display: 'flex', margin: '1%' }}

        onClick={() => { onClick() }}

      >

        <div className='image-button-img ' style={{ borderRadius: '100%', flex: 3, padding: 3, display: 'flex', width: '100%', backgroundImage: "linear-gradient(#ffe500,#ff7a01)", }}>


          <div style={{ borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, backgroundColor: `#9a54c3`, backgroundImage: `url(${images.iconBackClear})`, backgroundSize: 'cover', backgroundPosition: 'center', }}>





          </div>
        </div>

      </button> */}


      <div className={styles.gridContainer} style={{ gridGap: 2, padding: 4 }} >
        {
          wordBingo.map(function (item, i) {

            return <div key={'b' + i} style={{ fontSize: '4vh', fontFamily: 'GamerStation', aspectRatio: '1', backgroundSize: 'cover', backgroundImage: `url(${category == 4 ? gameBallImagesL[i] : gameBallImages[i]})`, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }} >
              {category != 4 && item}
            </div>



          })
        }

      </div>

      <div className={styles.gridContainerNumbers} >

        {
          numbers.map(function (item, i) {

            return <div key={"item" + item} style={{ backgroundColor: drawnNumbers && drawnNumbers.includes(item) ? 'red' : null, color: drawnNumbers && drawnNumbers.includes(item) ? 'white' : null, fontSize: category == 4 ? '1.8vh' : null }} className={styles.gridItem}>

              {category == 4 ? loteria?.find((item_) => item_.id == item)?.name : item}


            </div>

            // <Button type="button" onClick={() => selectNo(i)} style={{}} className={styles.gridItem}>
            //   {/* {item.no} */}
            //   <CardButton index={i} back={backcolor} size={width} item={item} />

            // </Button>




          })
        }

      </div>
    </div>
  </div>

}

// SentResetPassword.propTypes = {
//   email: PropTypes.string,
//   isOpen: PropTypes.bool,
//   toggle: PropTypes.func,
//   onSubmit: PropTypes.func,
// }

// SentResetPassword.defaultProps = {
//   email: '',
//   isOpen: false,
//   toggle: () => {},
//   onSubmit: () => {},
// }

export default ShowAll
