import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import styles from './wallet.module.scss'
import { images } from 'theme'
import Certificates from 'pageComponents/Certificates'
import Coins from 'pageComponents/Coins'

const Wallet = () => {
  const history = useHistory()
  const [backcolor, setBackcolor] = useState('purple')
  const [currentTab, setCurrentTab] = useState('1')

  const tabs = [
    {
      id: 1,
      tabTitle: 'Active',
      // title: 'Title 1',
      content: <Certificates type={1} />,
    },
    {
      id: 2,
      tabTitle: 'Redeemed',
      // title: 'Title 2',
      content: <Certificates type={2} />,
    },
    {
      id: 3,
      tabTitle: 'Expired',
      // title: 'Title 3',
      content: <Certificates type={3} />,
    },
    {
      id: 4,
      tabTitle: 'Coins',
      // title: 'Title 3',
      content: <Coins />,
    },
  ]

  const handleTabClick = (e) => {
    setCurrentTab(e.target.id)
  }

  return (
    <>
      <div className={styles.root}>
        <div className={styles.container}>
          <div className={styles.sideContainer}></div>

          <div
            style={{
              backgroundSize: 'cover',
              aspectRatio: '8/16',
              background: `url(${images.Sunburst}) , linear-gradient(125.07deg, #ff3b86 0%, #9d7fe3 66.18%)`,
            }}
            className={styles.middleContainer}
          >
            <div className={styles.menuBar}>
              <div className={styles.width20}>
                <button
                  type="button"
                  style={{
                    width: '5vh',
                    aspectRatio: '1',
                    alignItems: 'center',
                    flexDirection: 'column',
                    display: 'flex',
                  }}
                  onClick={() => history.goBack()}
                >
                  <div
                    className="image-button-img"
                    style={{
                      borderRadius: '100%',
                      flex: 3,
                      padding: 3,
                      display: 'flex',
                      width: '100%',
                      backgroundImage: 'linear-gradient(#ffe500,#ff7a01)',
                    }}
                  >
                    <div
                      style={{
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flex: 1,
                        backgroundColor: `${backcolor}`,
                        backgroundImage: `url(${images.iconBackClear})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        width: '54px',
                      }}
                    ></div>
                  </div>
                </button>
              </div>
            </div>
            <div className={styles.topRow}></div>

            <div className={styles.tabs}>
              {tabs.map((tab, i) => (
                <button
                  key={i}
                  id={tab.id}
                  disabled={currentTab === `${tab.id}`}
                  onClick={handleTabClick}
                >
                  {tab.tabTitle}
                </button>
              ))}
            </div>
            <div className={styles.content}>
              {tabs.map((tab, i) => (
                <div key={i}>
                  {currentTab === `${tab.id}` && <>{tab.content}</>}
                </div>
              ))}
            </div>

            <div className={styles.bottomRow}></div>
          </div>

          <div className={styles.sideContainer}></div>
        </div>
      </div>
    </>
  )
}

// Notifications.propTypes = {}
// Notifications.defaultProps = {}

export default Wallet
