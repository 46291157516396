// import logo from 'assets/images/logo.svg'
import logoold from 'assets/images/logoold.png'
import profile from 'assets/images/profile.png'
import fnfLogo from 'assets/images/fnf_logo.png'
import Sunburst from 'assets/images/Sunburst.png'

import imageBackground from 'assets/images/imageBackground.jpg'

import game1Background from 'assets/images/gamebgs/game1Background.png'
import game2Background from 'assets/images/gamebgs/game2Background.png'
import game3Background from 'assets/images/gamebgs/game3Background.png'
import game4Background from 'assets/images/gamebgs/game4Background.png'
import game5Background from 'assets/images/gamebgs/game5Background.png'
import game6Background from 'assets/images/gamebgs/game6Background.png'
import game7Background from 'assets/images/gamebgs/game7Background.png'
import game8Background from 'assets/images/gamebgs/game8Background.png'
import game9Background from 'assets/images/gamebgs/game9Background.png'
import game10Background from 'assets/images/gamebgs/game10Background.png'

import blueBall from 'assets/images/gameballs/blueBall.png'
import redBall from 'assets/images/gameballs/redBall.png'
import yellowBall from 'assets/images/gameballs/yellowBall.png'
import greenBall from 'assets/images/gameballs/greenBall.png'
import greyBall from 'assets/images/gameballs/greyBall.png'

import btn_homeBottomT from 'assets/images/btn_homeBottomT.png'
import btn_homeBottom from 'assets/images/btn_homeBottom.png'

import btn_showAll from 'assets/images/btn_showAll.png'
import btn_callBingo from 'assets/images/btn_callBingo.png'
import btn_dauberMarker from 'assets/images/btn_dauberMarker.png'
import btn_mute from 'assets/images/btn_mute.png'
import btn_chat from 'assets/images/btn_chat.png'
import globe from 'assets/images/globe.png'

import bottomNotification from 'assets/images/icons/bottomNotification.png'
import bottomSchedule from 'assets/images/icons/bottomSchedule.png'
import bottomStore from 'assets/images/icons/bottomStore.png'
import bottomWallet from 'assets/images/icons/bottomWallet.png'

import iconBackClear from 'assets/images/iconBackClear.png'

import inkspot from 'assets/images/inkspot.png'

import dialogueFrame from 'assets/images/dialogueFrame.jpg'

import btn_Live from 'assets/images/btn_Live.png'
import btn_couch from 'assets/images/btn_couch.png'
import btn_BBTV from 'assets/images/btn_BBTV.png'
import btn_loteria from 'assets/images/btn_loteria.png'
import btn_pass from 'assets/images/btn_passOrPlay.png'

import btn_music from 'assets/images/btn_music.png'
import btn_bingoport from 'assets/images/btn_bingoport.png'

import btnLandscape from 'assets/images/btn_Lanscape.png'
import imageProfile from 'assets/images/imageProfile.png'
import btn_send from 'assets/images/icons/iconSend.png'

import dauber_bottle from 'assets/images/dauberBottle.png'
import dauber_tip from 'assets/images/dauberTip.png'
import one from 'assets/images/loteria/1.png'
import two from 'assets/images/loteria/2.png'
import three from 'assets/images/loteria/3.png'
import four from 'assets/images/loteria/4.png'
import five from 'assets/images/loteria/5.png'

import redeemedLeft from 'assets/images/RedeemedLeft.png'
import RedeemedRight from 'assets/images/RedeemedRight.png'
import walletBackground from 'assets/images/walletBackground.png'
import RedeemedStamp from 'assets/images/RedeemedStamp.png'
import LargerCard from 'assets/images/LargerCard.png'
import largerPreviewCard from 'assets/images/largerPreviewCard.png'
import previewCard from 'assets/images/previewCard.png'
import roundFrame from 'assets/images/roundFrame.png'

import btnProfile from 'assets/images/btn_profile.png'
import btnLandscapeRed from 'assets/images/btn_Lanscape_Red.png'

import iconEdit from 'assets/images/iconEdit.png'
import iconPassword from 'assets/images/iconPassword.png'
import iconLogout from 'assets/images/iconLogout.png'
import iconSettings from 'assets/images/iconSettings.png'
import iconDelete from 'assets/images/iconDelete.png'

import usaFlag from 'assets/images/usa-flag.png'
import calendar from 'assets/images/calendar.png'

import coins from 'assets/images/coins.png'
import mask from 'assets/images/New Project.png'

export const images = {
  // logo,
  logoold,
  profile,
  fnfLogo,
  Sunburst,

  imageBackground,

  game1Background,
  game2Background,
  game3Background,
  game4Background,
  game5Background,
  game6Background,
  game7Background,
  game8Background,
  game9Background,
  game10Background,

  blueBall,
  redBall,
  yellowBall,
  greenBall,
  greyBall,

  btn_homeBottomT,
  btn_homeBottom,

  btn_showAll,
  btn_callBingo,
  btn_dauberMarker,
  btn_mute,
  btn_chat,
  globe,

  bottomNotification,
  bottomSchedule,
  bottomStore,
  bottomWallet,

  iconBackClear,
  inkspot,
  dialogueFrame,

  btn_Live,
  btn_couch,
  btn_BBTV,
  btn_loteria,
  btn_pass,

  btn_music,
  btn_bingoport,

  btnLandscape,
  imageProfile,
  btn_send,

  dauber_bottle,
  dauber_tip,
  one,
  two,
  three,
  four,
  five,

  redeemedLeft,
  RedeemedRight,
  walletBackground,
  RedeemedStamp,
  LargerCard,
  largerPreviewCard,
  previewCard,
  roundFrame,

  btnProfile,
  btnLandscapeRed,

  iconEdit,
  iconPassword,
  iconLogout,
  iconSettings,
  iconDelete,

  usaFlag,
  calendar,
  coins,
  mask,
}

export default {}
