import React from 'react'
import PropTypes from 'prop-types'

import Spinner from '../../components/Spinner'
import { images } from 'theme'
import styles from './imagebutton.module.scss'
const ImageButton = ({
  back,
  className,
  style,
  label,
  onClick,
  isLoading,
  disabled,
  children,
  image,
  flex = [4, 1],
  ...others
}) => (
  <button
    type="button"
    style={{ width: '100%', alignItems: 'center', flexDirection: 'column', display: 'flex', height: '100%' }}

    onClick={onClick}
    disabled={disabled || isLoading}
    {...others}
  >

    <div className={styles.imgButtonImg} style={{ borderRadius: '28%', flex: flex[0], padding: 3, display: 'flex', aspectRatio: '1', backgroundImage: "linear-gradient(#ffe500,#ff7a01)", }}>


      <div style={{ borderRadius: '28%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: `${back}`, backgroundImage: `url(${images.btn_homeBottomT})`, backgroundSize: 'cover', backgroundPosition: 'center', flex: 1, }}>
        {isLoading ? (
          <Spinner color="white" isLoading />
        ) : (
          <>
            <img style={{ width: '70%' }} src={image} />

          </>
        )}
      </div>
    </div>
    <div className={styles.buttonText} style={{ flex: flex[1], fontFamily: 'BebasNeue', color: 'white', fontSize: '2.5vh', }}>

      {label}
    </div>
  </button >
)

ImageButton.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.object),
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
}

ImageButton.defaultProps = {
  label: '',
  className: '',
  style: {},
  onClick: () => { },
  children: null,
  disabled: false,
  isLoading: false,
}

export default ImageButton
