import React from 'react'
import PropTypes from 'prop-types'
import styles from './Toggle.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

const Toggle = ({
  title,
  checked,
  onChange,
  value,
  disabled,
  status

}) => {

  return <div className={styles.checkboxWrapper}>

    <input checked={checked} onChange={e => disabled ? null : onChange(value, e.target.checked)} type="checkbox" value="1" name="type" className={styles.toggle} />

    <div style={{ background: disabled ? '#a5a5a573' : null }} className={styles.switch}>
      <div style={{ background: disabled ? '#a5a5a573' : null }} className={styles.inner}>
        <div className={styles.disc}></div>
      </div>
    </div>
    <span style={{ color: disabled ? '#4a4a4a' : '#ffe500', fontFamily: "BebasNeue", marginLeft: 66, alignItems: 'center' }}>{title}</span>
    <span style={{ backgroundColor: 'white', width: 40, textAlign: 'center', borderRadius: 20, position: 'absolute', right: 0, fontFamily: "BebasNeue", color: disabled ? '#4a4a4a' : status == 'ON' ? "green" : 'red' }}>{status}</span>
  </div>
}




export default Toggle
