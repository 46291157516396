import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/Modal'
import FontIcon from 'components/FontIcon'
import Button from 'components/Button'
import { useHistory } from 'react-router-dom'
import styles from './SoundSettings.module.scss'
import { path } from 'utils/const'
import { images } from 'assets'
import CardButton from 'pageComponents/CardButton'
import GameModal from 'pageComponents/GameModal'
import { getDatabase, ref, set } from 'firebase/database'
import Toggle from 'components/Toggle/Toggle'
import Button3d from 'components/Button3d'
const SoundSettings = ({
  userid,
  onClick,
  settings
}) => {
  const db = getDatabase();

  const [settings_muteall, setSettings_muteall] = useState(settings.muteall);
  const [settings_mutevalidchecks, setSettings_mutevalidchecks] = useState(settings.mutevalidchecks);
  const [settings_muteinvalidchecks, setSettings_muteinvalidchecks] = useState(settings.muteinvalidchecks);
  const [settings_muterobo, setSettings_muterobo] = useState(settings.muterobo);
  const [settings_mutewinsounds, setSettings_mutewinsounds] = useState(settings.mutewinsounds);
  const [disabledall, setDisabledAll] = useState(settings.muteall);

  const updateVal = (type, val) => {

    if (type == 'muteall') {
      setSettings_muteall(val)
      if (val == true) {
        setDisabledAll(true)
      }
      else { setDisabledAll(false) }
    }
    if (type == 'mutevalidchecks') {
      setSettings_mutevalidchecks(val)
    }
    if (type == 'muteinvalidchecks') {
      setSettings_muteinvalidchecks(val)
    }
    if (type == 'muterobo') {
      setSettings_muterobo(val)
    }
    if (type == 'mutewinsounds') {
      setSettings_mutewinsounds(val)
    }


    set(ref(db, "/users/" + userid + "/settings/" + type), val);
  }
  const modalContent = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>


        <div style={{ display: 'flex', gap: 16, flexDirection: 'column' }}>
          <Toggle status={settings_muteall ? "ON" : "OFF"} title={'Mute All Sounds'} checked={settings_muteall} onChange={updateVal} type="checkbox" value='muteall' />
          {/* <input checked={settings_muteall} onChange={e => updateVal('muteall', e.target.checked)} type="checkbox" value={settings.muteall} name="type" /> Mute All Sounds */}
          <Toggle status={settings_mutevalidchecks ? "ON" : "OFF"} disabled={disabledall} checked={settings_mutevalidchecks} onChange={updateVal} type="checkbox" value="mutevalidchecks" title="Valid Check Sounds" />
          <Toggle status={settings_muteinvalidchecks ? "ON" : "OFF"} disabled={disabledall} checked={settings_muteinvalidchecks} onChange={updateVal} type="checkbox" value="muteinvalidchecks" title="Invalid Check Sounds" />
          <Toggle status={settings_muterobo ? "ON" : "OFF"} disabled={disabledall} checked={settings_muterobo} onChange={updateVal} type="checkbox" value="muterobo" title="Host/Robo Sounds" />
          <Toggle status={settings_mutewinsounds ? "ON" : "OFF"} disabled={disabledall} checked={settings_mutewinsounds} onChange={updateVal} type="checkbox" value="mutewinsounds" title="Win Sounds" />


        </div>
        <div style={{ width: '50%', marginTop: 16, alignSelf: 'center', display: 'flex' }}>

          <Button3d className={`btn-red`} label={'Close'} onClick={() => onClick()} ></Button3d>
        </div >
      </div>
    )
  }


  return <GameModal content={modalContent()} ></GameModal>
}

// SentResetPassword.propTypes = {
//   email: PropTypes.string,
//   isOpen: PropTypes.bool,
//   toggle: PropTypes.func,
//   onSubmit: PropTypes.func,
// }

// SentResetPassword.defaultProps = {
//   email: '',
//   isOpen: false,
//   toggle: () => {},
//   onSubmit: () => {},
// }

export default SoundSettings
