import React, { useEffect, useState, useRef } from 'react'
import { useHistory, } from 'react-router-dom'
import styles from './notifications.module.scss'
import { images } from 'theme'


import NotiCard from 'pageComponents/NotiCard';
import {
  getFirestore,
  collection,
  query as fsquery,
  where,
  onSnapshot,
  orderBy,
  limit,
} from "firebase/firestore";
import { auth } from 'utils/firebase'
const Notifications = () => {
  const history = useHistory();
  const [backcolor, setBackcolor] = useState("purple");
  const [currentTab, setCurrentTab] = useState('1');
  const [notifications, setNotifications] = useState([]);
  const [noNotifications, setNoNotifications] = useState('1');
  const fsdb = getFirestore();
  useEffect(() => {
    fetchData()
  }, []);

  const fetchData = async () => {
    // setLoading(true);

    const q1 = fsquery(
      collection(fsdb, "pushnotifications"),
      where("uid", "==", auth.currentUser.uid),
      orderBy("timestamp", "desc"),
      limit(10)
    );

    const q2 = fsquery(
      collection(fsdb, "pushnotifications"),
      where("uid", "==", "0"),
      where("target", "==", "usa"),
      orderBy("timestamp", "desc"),
      limit(10)
    );

    const noti1 = onSnapshot(q1, (querySnapshot) => {
      const notifications1 = querySnapshot.docs.map((doc) => doc.data());
      setNotifications((prevNotifications) => [
        ...prevNotifications,
        ...notifications1,
      ]);
    });

    const noti2 = onSnapshot(q2, (querySnapshot) => {
      const notifications2 = querySnapshot.docs.map((doc) => doc.data());
      setNotifications((prevNotifications) => [
        ...prevNotifications,
        ...notifications2,
      ]);
    });

    return () => {
      noti1(); // Unsubscribe from snapshots when component unmounts
      noti2(); // Unsubscribe from snapshots when component unmounts
    };
  };



  return (
    <>
      <div className={styles.root}>
        <div className={styles.container}>

          <div className={styles.sideContainer}>

          </div>

          <div style={{ backgroundSize: 'cover', aspectRatio: '8/16', background: `url(${images.Sunburst}) , linear-gradient(125.07deg, #ff3b86 0%, #9d7fe3 66.18%)` }} className={styles.middleContainer}>

            <div className={styles.menuBar}>

              <div className={styles.width20}>
                <button
                  type="button"
                  style={{ width: '5vh', aspectRatio: '1', alignItems: 'center', flexDirection: 'column', display: 'flex', }}
                  onClick={() => history.goBack()}
                >
                  <div className='image-button-img' style={{ borderRadius: '100%', flex: 3, padding: 3, display: 'flex', width: '100%', backgroundImage: "linear-gradient(#ffe500,#ff7a01)", }}>
                    <div style={{ borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, backgroundColor: `${backcolor}`, backgroundImage: `url(${images.iconBackClear})`, backgroundSize: 'cover', backgroundPosition: 'center', width: '54px' }}>
                    </div>
                  </div>
                </button>
              </div>

            </div>
            <div className={styles.middleRow} style={{ flex: 1, overflowY: 'auto' }}>
              {/* {!noNotifications && notifications.length == 0 && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>

                <Spinner color="white" isLoading={notifications.length == 0} />
              </div>} */}


              {
                notifications.length == 0 && <div style={{ textAlign: 'center', color: '#ffffff7d' }}>No notifications</div>
              }
              {
                notifications.map(function (item, i) {

                  return <NotiCard key={i}

                    title={item.title}

                    subtitle={item.body}
                    date={item.datetime}


                  />



                })
              }
            </div>


            <div>

            </div>

          </div>

          <div className={styles.sideContainer}>

          </div>





        </div>
      </div >

    </>
  )
}

// Notifications.propTypes = {}
// Notifications.defaultProps = {}

export default Notifications
