import React, { Suspense, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { actions } from 'slices/app.slice'
import { path } from 'utils/const'
import Fallback from 'components/Fallback'
import Spinner from 'components/Spinner'
import Notifications from 'pages/notifications'
import Locations from 'pages/locations'
import Game from 'pages/game'
import Wallet from 'pages/wallet'
import Profile from 'pages/profile'
import CuoponPreview from 'pages/cuoponPreview'
import {
  getDatabase,
  ref,
  onValue,
  set,
  onChildChanged,
} from 'firebase/database'
import Main from 'pages/profile/main'
import Edit from 'pages/profile/edit'
import Delete from 'pages/profile/delete'
import Reset from 'pages/profile/reset'
import Schedule from 'pages/schedule'
import Map from 'pages/map'

const Auth = React.lazy(() => import('./pages/auth'))
const Dashboard = React.lazy(() => import('./pages/dashboard'))

function Router() {
  const dispatch = useDispatch()
  const { checked, loggedIn, me } = useSelector((state) => state.app)
  const db = getDatabase()
  useEffect(() => {
    dispatch(actions.authenticate())

    if (loggedIn) {
      const currentGameRef = ref(db, 'users/' + me.id + '/uuid/')
      onChildChanged(currentGameRef, async (data) => {
        const uuid = localStorage.getItem('@uuid')
        console.log('onChildCHange', data)
        // alert(JSON.stringify(data)+"     "+JSON.stringify(uuid))
        if (JSON.stringify(data) != JSON.stringify(uuid)) {
          alert(
            'Sorry, No Dual Logins!',
            'You have already signed in with another device, you will be signed out of this one now.',
          )
          dispatch(actions.logout())
          // Alert.alert("Sorry, No Dual Logins!", "You have already signed in with another device, you will be signed out of this one now.", [

          //   {
          //     text: "OK", onPress: () => {

          //       signOut(auth).catch((error) => console.log("Error logging out: ", error));

          //     }

          //   },
          // ]);
        }
      })
    }
  }, [])

  if (!checked) {
    return (
      <div className="app-loader-container">
        <Spinner size="4rem" color="white" isLoading />
      </div>
    )
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<Fallback />}>
        {!loggedIn ? (
          <Switch>
            <Route path="/">
              <Auth />
            </Route>
            <Redirect to="/login" />
          </Switch>
        ) : (
          <Switch>
            <Route path={path.dashboard}>
              <Dashboard />
            </Route>
            <Route path={path.notifications}>
              <Notifications />
            </Route>
            <Route path={path.schedule}>
              <Schedule />
            </Route>
            <Route path={path.locations}>
              <Locations />
            </Route>
            <Route path={path.game}>
              <Game />
            </Route>
            <Route path={path.wallet}>
              <Wallet />
            </Route>
            <Route path={path.cuoponPreview}>
              <CuoponPreview />
            </Route>
            <Route path={path.profile}>
              <Profile />
            </Route>

            <Route path={path.edit}>
              <Edit />
            </Route>
            <Route path={path.delete}>
              <Delete />
            </Route>
            <Route path={path.reset}>
              <Reset />
            </Route>
            <Route path={path.map}>
              <Map />
            </Route>
            <Redirect to={path.dashboard} />
          </Switch>
        )}
      </Suspense>
    </BrowserRouter>
  )
}

export default Router
