import React, { useEffect, useState, useRef } from 'react'
import { useHistory, } from 'react-router-dom'
import styles from './schedule.module.scss'
import { images } from 'theme'
import {
  getDatabase,
  ref,
  set,
  onValue,
  query,
  orderByChild,
  equalTo,
} from "firebase/database";

import NotiCard from 'pageComponents/NotiCard';

import { auth } from 'utils/firebase'
import ScheduleCard from 'pageComponents/ScheduleCard';
const Schedule = () => {
  const history = useHistory();
  const [backcolor, setBackcolor] = useState("purple");
  const [currentTab, setCurrentTab] = useState('1');
  const [reminders, setReminders] = useState([]);
  const [noNotifications, setNoNotifications] = useState('1');

  const db = getDatabase();
  useEffect(() => {
    getReminders()
  }, []);

  const getReminders = () => {

    const remRef = query(
      ref(db, "reminders/"),
      orderByChild("userid"),
      equalTo(auth.currentUser.uid)
    );
    onValue(remRef, (snapshot) => {
      let temparr = [];
      if (snapshot.exists()) {
        snapshot.forEach((child) => {
          temparr.push(child.val());
          console.log(child.val())
        });
        setReminders(temparr);
      } else {
        setReminders(temparr);
      }
    });
  };



  return (
    <>
      <div className={styles.root}>
        <div className={styles.container}>

          <div className={styles.sideContainer}>

          </div>

          <div style={{ backgroundSize: 'cover', aspectRatio: '8/16', background: `url(${images.Sunburst}) , linear-gradient(125.07deg, #ff3b86 0%, #9d7fe3 66.18%)` }} className={styles.middleContainer}>

            <div className={styles.menuBar}>

              <div className={styles.width20}>
                <button
                  type="button"
                  style={{ width: '5vh', aspectRatio: '1', alignItems: 'center', flexDirection: 'column', display: 'flex', }}
                  onClick={() => history.goBack()}
                >
                  <div className='image-button-img' style={{ borderRadius: '100%', flex: 3, padding: 3, display: 'flex', width: '100%', backgroundImage: "linear-gradient(#ffe500,#ff7a01)", }}>
                    <div style={{ borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, backgroundColor: `${backcolor}`, backgroundImage: `url(${images.iconBackClear})`, backgroundSize: 'cover', backgroundPosition: 'center', width: '54px' }}>
                    </div>
                  </div>
                </button>
              </div>

            </div>
            <div className={styles.middleRow} style={{ flex: 1, overflowY: 'auto' }}>
              {/* {!noNotifications && notifications.length == 0 && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>

                <Spinner color="white" isLoading={notifications.length == 0} />
              </div>} */}


              {
                reminders.length == 0 && <div style={{ textAlign: 'center', color: '#ffffff7d' }}>No Schedules</div>
              }
              {
                reminders.map(function (item, i) {

                  return <ScheduleCard key={i}
                    logo={item.logo}
                    title={item.locationname}
                    locationid={item.locationid}
                    subtitle={item.body}
                    date={item.datetime}


                  />



                })
              }
            </div>


            <div>

            </div>

          </div>

          <div className={styles.sideContainer}>

          </div>





        </div>
      </div >

    </>
  )
}

// Schedule.propTypes = {}
// Schedule.defaultProps = {}

export default Schedule
