import React from 'react'

import styles from './LImageModal.module.scss'

import { LazyLoadImage } from 'react-lazy-load-image-component';
const LImageModal = ({
  image, parentFunction, autoClose
}) => {


  return <div  >
    <div className={styles.wrapper} >
      <div style={{ display: 'flex', flexDirection: 'column', backgroundSize: 'cover', flex: 1, backgroundColor: 'black', justifyContent: 'center', alignItems: 'center' }}>


        {/* <img style={{ width: '100%' }} src={image} /> */}
        <div className="animate__animated animate__bounceIn" >
          <LazyLoadImage

            // alt={"image.alt"}
            // height={image.height}
            // placeholderSrc="https://placehold.co/600x400?text=Hello+World"
            loading='lazy'
            effect="blur"
            src={image} // use normal <img> attributes as props
            onLoad={() => {
              parentFunction()
            }}
            height={'auto'}
            width={'100%'} />

        </div>

      </div>
    </div>
  </div>
}



export default LImageModal
