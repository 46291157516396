import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styles from './schedule.module.scss'
import Button3d from 'components/Button3d'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import Button from 'components/Button'
import { useSelector } from 'react-redux'
import { getDatabase, onValue, ref, set } from 'firebase/database'
import { images } from 'assets'
import { auth } from 'utils/firebase'
const ScheduleCard = ({
    title, subtitle, date, logo, locationid,
    ...others
}) => {
    // const db = getDatabase();
    // const { me } = useSelector((state) => state.app)
    // const lastsession = useRef(null)








    return <>
        <div style={{ borderRadius: '28px', flex: 1, display: 'flex', backgroundImage: "linear-gradient(#ffe500,#ff7a01)", margin: '16px 8px 16px 16px', }}>



            <div className={styles.locationCard}>



                <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                    <div className={styles.rightComponent}>
                        <img style={{ width: '100%', }} src={logo} />
                        {/* {JSON.stringify(lastsession.current)} */}

                    </div>
                    <div className={styles.leftComponent}>
                        <div className={styles.locationName} >{title}</div>
                        <div className={styles.capitalLetters} style={{ display: 'flex' }} >

                            <div>{subtitle}</div>
                        </div>

                        <div className={styles.capitalLetters} style={{ color: 'gray', display: 'flex' }}>
                            <div>{date}</div>
                        </div>
                        {/* <div style={{ display: 'flex' }}>
                            <FontAwesomeIcon style={{ marginRight: '5px', color: '#ff7a01', visibility: 'hidden' }} icon={icon({ name: 'x' })} />
                            <Button className={styles.capitalLetters} style={{ fontSize: '3vh', fontWeight: '500' }} label={'+ add reminder'}></Button>
                        </div> */}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        <div className={styles.width20} >
                            <button
                                type="button"
                                style={{ width: '5vh', aspectRatio: '1', alignItems: 'center', flexDirection: 'column', display: 'flex', }}
                                onClick={() => {
                                    set(
                                        ref(
                                            getDatabase(),
                                            "reminders/" + locationid + "" + auth.currentUser.uid
                                        ),
                                        {}
                                    );
                                }}
                            >
                                <div className='image-button-img' style={{ borderRadius: '100%', flex: 3, padding: 3, display: 'flex', width: '100%', backgroundImage: "linear-gradient(#ffe500,#ff7a01)", }}>
                                    <div style={{ color: 'white', textAlign: 'center', fontSize: 12, borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, backgroundColor: `red`, backgroundSize: 'cover', backgroundPosition: 'center', width: '54px' }}>
                                        X                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>

            </div >


        </div >
    </>
}

ScheduleCard.propTypes = {
    label: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.objectOf(PropTypes.object),
    children: PropTypes.node,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
}

ScheduleCard.defaultProps = {
    label: '',
    className: '',
    style: {},
    onClick: () => { },
    children: null,
    disabled: false,
    isLoading: false,
}

export default ScheduleCard
