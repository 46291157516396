import React, { useEffect, useState, useRef } from 'react'
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'
import Input from 'components/Input'
import Button3d from 'components/Button3d'
import styles from './reset.module.scss'
import { images } from 'assets'
import validate, { tests } from 'utils/validate'
import { auth } from 'utils/firebase'
import { sendPasswordResetEmail } from "firebase/auth";
const Reset = () => {
  const history = useHistory();

  const [currentTab, setCurrentTab] = useState('1');
  const [backcolor, setBackcolor] = useState("purple");
  const [successMsg, setSuccessMsg] = useState("");
  const [resErr, setResError] = useState('')
  const [input, setInput] = useState({
    email: '',

  })
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState({})
  const handleOnChange = ({ target: { name, value } }) => {
    setInput((prev) => ({ ...prev, [name]: value }))
    setError((prev) => ({ ...prev, [name]: '' }))
    setResError('')
  }
  const handleSubmit = async () => {
    // validation
    const result = validate(input, tests)
    setError(result.errors)
    if (result.isError) return

    // login action
    setLoading(true)

    sendPasswordResetEmail(auth, input.email)
      .then(() => {
        setSuccessMsg('Email sent to ' + input.email + '. Follow the instructions in the email to reset your password.')
        console.log("Success: Password Reset Email sent.");
        setInput((prev) => ({ ...prev, ['email']: "" }))
        // navigation.navigate("Login");
        setLoading(false)
      })
      .catch((error) => {
        //
        console.log(error.message);
        if (error.message == "Firebase: Error (auth/user-not-found).") {
          setResError("User not found");
          console.log("user not found")
        } else {
          setResError(error.message);
        }
        setLoading(false)

      });
  }
  return (
    <>
      <div className={styles.root}>
        <div className={styles.container}>
          <div className={styles.sideContainer}>
          </div>
          <div style={{ backgroundSize: 'cover', aspectRatio: '8/16', background: `url(${images.Sunburst}) , linear-gradient(125.07deg, #ff3b86 0%, #9d7fe3 66.18%)` }} className={styles.middleContainer}>
            <div className={styles.menuBar}>
              <div className={styles.width20}>
                <button
                  type="button"
                  style={{ width: '5vh', aspectRatio: '1', alignItems: 'center', flexDirection: 'column', display: 'flex', }}
                  onClick={() => history.goBack()}
                >
                  <div className='image-button-img' style={{ borderRadius: '100%', flex: 3, padding: 3, display: 'flex', width: '100%', backgroundImage: "linear-gradient(#ffe500,#ff7a01)", }}>
                    <div style={{ borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, backgroundColor: `${backcolor}`, backgroundImage: `url(${images.iconBackClear})`, backgroundSize: 'cover', backgroundPosition: 'center', width: '54px' }}>
                    </div>
                  </div>
                </button>
              </div>

            </div>

            {/**password reset page start */}
            <div style={{ marginLeft: '16px', marginRight: '16px', flex: '1' }}>
              <div className={styles.pageTitle}>
                reset password
              </div>
              <div>
                <div className={styles.description}>Enter your registered email below and we will send you a link to
                  reset your password
                </div>
                <Input
                  // label="Email"
                  name="email"
                  placeholder="Enter Email"
                  error={error.email}
                  value={input.email}
                  onChange={handleOnChange}
                  onKeyDown={(e) => { }}
                />
                <Button3d
                  label="Reset"
                  className={`btn-yellow`}
                  onClick={handleSubmit}
                  isLoading={isLoading}
                />
                <div className={styles.description} style={{ marginTop: 16 }}>{successMsg}  </div>
              </div>
            </div>
            {/**password reset page end */}




          </div>

          <div className={styles.sideContainer}>

          </div>





        </div>
      </div >

    </>
  )
}

// Notifications.propTypes = {}
// Notifications.defaultProps = {}

export default Reset
