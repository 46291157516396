import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styles from './noticard.module.scss'
import Button3d from 'components/Button3d'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import Button from 'components/Button'
import { useSelector } from 'react-redux'
import { getDatabase, onValue, ref } from 'firebase/database'
const NotiCard = ({
    title, subtitle, date,
    ...others
}) => {
    // const db = getDatabase();
    // const { me } = useSelector((state) => state.app)
    // const lastsession = useRef(null)








    return <>
        <div style={{ borderRadius: '28px', flex: 1, display: 'flex', backgroundImage: "linear-gradient(#ffe500,#ff7a01)", margin: '16px 8px 16px 16px', }}>



            <div className={styles.locationCard}>

                <div className={styles.locationName} >{title}</div>

                <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                    <div className={styles.leftComponent}>

                        <div className={styles.capitalLetters} style={{ display: 'flex' }} >

                            <div>{subtitle}</div>
                        </div>

                        <div className={styles.capitalLetters} style={{ color: 'gray', display: 'flex' }}>
                            <div>{date}</div>
                        </div>
                        {/* <div style={{ display: 'flex' }}>
                            <FontAwesomeIcon style={{ marginRight: '5px', color: '#ff7a01', visibility: 'hidden' }} icon={icon({ name: 'x' })} />
                            <Button className={styles.capitalLetters} style={{ fontSize: '3vh', fontWeight: '500' }} label={'+ add reminder'}></Button>
                        </div> */}
                    </div>

                </div>
                {/* <div className={styles.capitalLetters} style={{ display: 'flex' }}>
                        <FontAwesomeIcon style={{ marginRight: '5px', color: '#ff7a01' }} icon={icon({ name: 'phone' })} />
                        <div> {phone}</div>
                    </div> */}
                {/* <div className={styles.capitalLetters} style={{ color: 'gray', display: 'flex' }}><FontAwesomeIcon style={{ marginRight: '5px', color: '#ff7a01' }} icon={icon({ name: 'calendar' })} />
                        <div>{schedule}</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <FontAwesomeIcon style={{ marginRight: '5px', color: '#ff7a01', visibility: 'hidden' }} icon={icon({ name: 'x' })} />
                        <Button className={styles.capitalLetters} style={{ fontSize: '3vh', fontWeight: '500' }} label={'+ add reminder'}></Button>
                    </div> */}
            </div>


        </div>
    </>
}

NotiCard.propTypes = {
    label: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.objectOf(PropTypes.object),
    children: PropTypes.node,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
}

NotiCard.defaultProps = {
    label: '',
    className: '',
    style: {},
    onClick: () => { },
    children: null,
    disabled: false,
    isLoading: false,
}

export default NotiCard
