import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/Modal'
import FontIcon from 'components/FontIcon'
import Button from 'components/Button'
import { useHistory } from 'react-router-dom'
import styles from './LiveBingoListItem.module.scss'
import { path } from 'utils/const'
var CryptoJS = require("crypto-js");
const LiveBingoListItem = ({ dateobject,
  token,
  address,
  address2,
  phone,
  schedule,
  btnEnabled,
  btnDisabledText,
  titlemain,
  date,
  titlesub,
  border = false,
  last,
  isAlert = false,
  pic,
  locationRef,
  status,
  locationid,
  ismaplocation = false,
  distance = 0,
  timetaken = 0,
  islogoshow,
  category = 1,
  user_ }) => {

  const history = useHistory()
  const Encrypt = (word, key = 'share') => {
    let encJson = CryptoJS.AES.encrypt(JSON.stringify(word), key).toString()
    let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson))
    return encData
  }


  const joinRoom = (allowjoin) => {

    history.push(path.game + "?code=" + Encrypt(locationRef))
  }

  return <div onClick={joinRoom}>{titlemain}</div>
}

// SentResetPassword.propTypes = {
//   email: PropTypes.string,
//   isOpen: PropTypes.bool,
//   toggle: PropTypes.func,
//   onSubmit: PropTypes.func,
// }

// SentResetPassword.defaultProps = {
//   email: '',
//   isOpen: false,
//   toggle: () => {},
//   onSubmit: () => {},
// }

export default LiveBingoListItem
