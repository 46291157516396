import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  getFirestore,
  collection,
  query as fsquery,
  where,
  onSnapshot,
  updateDoc,
  doc,
} from "firebase/firestore"
import styles from './cuoponPreview.module.scss'
import { images } from 'theme'
import Button3d from 'components/Button3d'
import GameModal from 'pageComponents/GameModal'

const CuoponPreview = ({ route }) => {
  const history = useHistory();
  const location = useLocation();
  const fsdb = getFirestore();
  const [backcolor, setBackcolor] = useState("purple");
  const [openGameModal, setOpenGameModal] = useState(false);
  const [claimId, setClaimId] = useState(null);
  const [certificates, setCertificates] = useState(location.state.certificate);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const modalContent = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>


        <div style={{ display: 'flex', gap: 16, flexDirection: 'column' }}>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              WARNING
            </div>
            <div className={styles.cardDescription}>
              You ARE ABOUT TO REDEEM A CERTIFICATE MAKE SURE A
              REPRESENTATIVE VIEWS THE CERTIFICATE BEFORE YOU PROCEED.

            </div>
          </div>

        </div>
        <div style={{ width: '100%', marginTop: 16, alignSelf: 'center', display: 'flex', justifyContent: 'space-between' }}>

          <Button3d style={{ width: '48%' }} className={`btn-red`} label={'go back'} onClick={() => setOpenGameModal(false)} ></Button3d>
          <Button3d style={{ width: '48%' }} className={`btn-green`} label={'Redeem'} onClick={() => { Claim() }}></Button3d>
        </div>

      </div>
    )
  }

  const askClaim = (id) => {
    setOpenGameModal(true);
    setClaimId(id);
  };
  const Claim = async () => {
    const certiRef = doc(fsdb, "sentcertificates", claimId);

    // Set the "capital" field of the city 'DC'
    await updateDoc(certiRef, {
      isclaimed: true,
    }).then(() => {
      setOpenGameModal(false);
      history.goBack();
    });
  };
  return (
    <>
      <div className={styles.root}>
        <div className={styles.container}>

          <div className={styles.sideContainer}>

          </div>

          <div style={{ backgroundSize: 'cover', aspectRatio: '8/16', background: `url(${images.Sunburst}) , linear-gradient(125.07deg, #ff3b86 0%, #9d7fe3 66.18%)` }} className={styles.middleContainer}>

            <div className={styles.menuBar}>

              <div className={styles.width20}>
                <button
                  type="button"
                  style={{ width: '5vh', aspectRatio: '1', alignItems: 'center', flexDirection: 'column', display: 'flex', }}
                  onClick={() => history.goBack()}
                >
                  <div className='image-button-img' style={{ borderRadius: '100%', flex: 3, padding: 3, display: 'flex', width: '100%', backgroundImage: "linear-gradient(#ffe500,#ff7a01)", }}>
                    <div style={{ borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, backgroundColor: `${backcolor}`, backgroundImage: `url(${images.iconBackClear})`, backgroundSize: 'cover', backgroundPosition: 'center', width: '54px' }}>
                    </div>
                  </div>
                </button>
              </div>

            </div>
            {/* <div className={styles.topRow}>


            </div> */}

            <div style={{ marginLeft: '16px', marginRight: '16px', display: 'flex', height: '100%', flexDirection: 'column' }}>
              <div style={{
                width: '100%',
                // height: '100%',
                backgroundSize: 'contain',
                backgroundImage: `url(${images.LargerCard})`,
                backgroundRepeat: 'no-repeat',
                height: ' 58%',
                // borderRadius: '15px',
                // marginBottom: '16px',
                // boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                // maskImage: `url(${images.LargerCard})`,
                // maskSize: 'contain',
                // maskRepeat: 'no-repeat'
              }}>
                <div style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  // justifyContent: 'space-evenly'
                }}>
                  <img style={{
                    width: '100%', padding: '10px',
                  }} src={certificates?.url} />
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '10px',
                    position: 'relative',
                    top: '30px'
                  }}>
                    <div style={{
                      color: '#953737',
                      fontFamily: 'BebasNeue'
                    }}>
                      {certificates?.certiname}
                    </div>
                    <div>
                      {certificates?.code}
                    </div>
                    <div style={{
                      color: '#953737',
                      fontSize: '11px'
                    }}>
                      Expires: {formatDate(certificates?.expiredate)}
                    </div>
                    <div style={{ marginTop: '5px' }}>
                      <Button3d style={{ width: '150px' }} className={`btn-green`} label={'Redeem'} onClick={() => { askClaim(certificates?.id) }}></Button3d>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ borderRadius: '15px', display: 'flex', backgroundImage: "linear-gradient(#ffe500,#ff7a01)", }}>
                <div className={styles.card}>
                  <div style={{
                    fontFamily: 'BebasNeue'
                  }}>
                    Redemption and Disclaimers
                  </div>
                  <div style={{
                    fontFamily: 'BebasNeue',
                    fontSize: '12px'
                  }}>
                    <div>1- Present Certificate to representative.</div>
                    <div>2- Make sure to click "redeem" when in presence of representative.</div>
                    <div>3- If calling ahead make sure to mention certificate.</div>
                  </div>
                  <div style={{
                    fontFamily: 'BebasNeue',
                    fontSize: '11px',
                    color: '#953737',
                  }}>
                    <li>One certificate per person</li>
                    <li>Not Valid after {certificates.certidata.extype == 1
                      ? certificates.certidata.daylimit + " days"
                      : certificates.certidata.exdate}</li>
                    <li>Not good with any other offer</li>
                    <li>"Expired" or "Redeemed" certificates will NOT be accepted</li>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.bottomRow}>

            </div>

          </div>

          <div className={styles.sideContainer}>

          </div>


          {
            openGameModal && <GameModal onClick={() => setOpenGameModal(false)} content={modalContent()} ></GameModal>
          }


        </div>
      </div >

    </>
  )
}

// Notifications.propTypes = {}
// Notifications.defaultProps = {}

export default CuoponPreview
