import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/Modal'
import FontIcon from 'components/FontIcon'
import Button from 'components/Button'
import { useHistory } from 'react-router-dom'
import styles from './Alert.module.scss'
import { path } from 'utils/const'
import { images } from 'assets'
import CardButton from 'pageComponents/CardButton'

const Alert = ({
  message, back,
  onClick
}) => {

  return <div  >
    <div className={styles.wrapper} onClick={() => onClick()}>
      <div style={{ display: 'flex', flexDirection: 'column', backgroundSize: 'cover', flex: 1, backgroundColor: '#000000bf', justifyContent: 'center', alignItems: 'center' }}>


        <div class="animate__animated animate__bounceIn" style={{ width: '94%', backgroundColor: '#ecedef', borderRadius: 16, }}>
          <div className={styles.imgButtonImg} style={{ borderRadius: 16, flex: 2, padding: 3, display: 'flex', backgroundImage: "linear-gradient(#ffe500,#ff7a01)", }}>


            <div className={styles.modalBackground} style={{ padding: '8%', borderRadius: 16, backgroundSize: 'cover', backgroundPosition: 'center', flex: 1, textAlign: 'center' }}>
              <div style={{ flex: 1, borderRadius: 16, height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <p className={styles.title}>{message.title}</p>
                <p className={styles.subtitle}>{message.subtitle}</p>
              </div>

            </div>
          </div>



        </div>

      </div>
    </div>
  </div>
}

// SentResetPassword.propTypes = {
//   email: PropTypes.string,
//   isOpen: PropTypes.bool,
//   toggle: PropTypes.func,
//   onSubmit: PropTypes.func,
// }

// SentResetPassword.defaultProps = {
//   email: '',
//   isOpen: false,
//   toggle: () => {},
//   onSubmit: () => {},
// }

export default Alert
