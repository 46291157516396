import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/Modal'
import FontIcon from 'components/FontIcon'
import Button from 'components/Button'
import { useHistory } from 'react-router-dom'
import styles from './Chat.module.scss'
import { path } from 'utils/const'
import { images } from 'assets'
import CardButton from 'pageComponents/CardButton'
import moment from 'moment'
import { useSelector } from 'react-redux'
import {
  getDatabase,
  ref,
  push,
  query,
  orderByChild,
  onValue,
  set,
} from 'firebase/database'
import Input from 'components/Input'
const Chat = ({ sessionNo, userId, back }) => {
  const db = getDatabase()
  const [chatMessages, setChatMessages] = useState([])
  const [chatMessage, setChatMessage] = useState('')
  const divRef = useRef(null)
  const { me } = useSelector((state) => state.app)
  useEffect(() => {
    console.log('render')

    console.log(sessionNo)
    const chatsRef = query(
      ref(db, 'chats/' + sessionNo),
      orderByChild('timestamp'),
    )

    onValue(chatsRef, (snapshot) => {
      let temparr = []
      if (snapshot.exists()) {
        snapshot.forEach((child) => {
          temparr.push(JSON.parse(JSON.stringify(child.val())))
        })
        setChatMessages(temparr)
        console.log(temparr)
        divRef?.current?.scrollIntoView({ behavior: 'smooth' })
      }
    })
    divRef?.current?.scrollIntoView({ behavior: 'smooth' })
  }, [])
  function makeid(length) {
    var result = ''
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }
  const sendChat = () => {
    let _id = makeid(32)
    if (chatMessage != '') {
      set(ref(db, 'chats/' + sessionNo + '/' + _id), {
        _id: _id,
        text: chatMessage,
        createdAt: moment().format('MM/DD/YYYY HH:mm:ss'),
        timestamp: moment().unix(),
        user: {
          _id: userId,
          name: me.fname + ' ' + me.lname,
          avatar: 'auth.currentUser.photoURL',
        },
      })
    }
    setChatMessage('')
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      sendChat()
    }
  }
  // alert(JSON.stringify(groupedData))
  return (
    <div
      className={styles.chatContainer}
      style={{
        background: `url(${back}) rgba(0, 0, 0, 0.5)`,
        backgroundSize: 'cover',
        backgroundBlendMode: 'multiply',
        backgroundSize: 'cover',
      }}
    >
      <div
        style={{
          flex: 1,
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {chatMessages &&
          chatMessages.map(function (item, i) {
            return (
              <div
                key={'chat' + i}
                className={
                  item.user?._id == userId
                    ? styles.chatBubbleRight
                    : styles.chatBubbleLeft
                }
              >
                <div
                  className={
                    item.user?._id == userId ? styles.right : styles.left
                  }
                >
                  <div>{item.text}</div>
                  <div style={{ fontSize: 10 }}> {item.createdAt}</div>
                </div>
              </div>
            )
          })}
        <div ref={divRef}>
          <div style={{ height: 125, width: 50 }}></div>
        </div>
      </div>

      <div
        style={{
          marginTop: '16px',
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <Input
          placeholder="Message"
          onChange={(e) => setChatMessage(e.target.value)}
          onKeyDown={(e) => handleKeyDown(e)}
          value={chatMessage}
          style={{ marginBottom: '0px', width: '100%' }}
        />
        <button
          type="button"
          style={{
            height: '100%',
            aspectRatio: '1',
            alignItems: 'center',
            flexDirection: 'column',
            display: 'flex',
            position: 'absolute',
            right: 0,
          }}
          onClick={() => sendChat()}
        >
          <div
            className="image-button-img"
            style={{
              borderRadius: '100%',
              flex: 3,
              padding: 3,
              display: 'flex',
              width: '100%',
              backgroundImage: 'linear-gradient(#ffe500,#ff7a01)',
            }}
          >
            <div
              style={{
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flex: 1,
                backgroundImage: `url(${images.btn_send})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            ></div>
          </div>
        </button>
      </div>
    </div>
  )
}

export default Chat
