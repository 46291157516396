import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styles from './coins.module.scss'
import {
  getFirestore,
  collection,
  query as fsquery,
  where,
  onSnapshot,
  orderBy,
  updateDoc,
  doc,
} from 'firebase/firestore'
import moment from 'moment'
import { images } from 'theme'
import { path } from 'utils/const'
import GameModal from 'pageComponents/GameModal'
import Button3d from 'components/Button3d'

const fsdb = getFirestore()

const Coins = ({ expired, redeemed, ...others }) => {
  const { me } = useSelector((state) => state.app)
  const history = useHistory()
  const [sentCoins, setSentCoins] = useState([])
  const [claimId, setClaimId] = useState(null)
  const [claimModalVisible, setClaimModalVisible] = useState(false)
  const [totalCoins, setTotalCoins] = useState(0)
  useEffect(() => {
    getCoins()
  }, [])

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
    console.log(new Date(dateString).toLocaleDateString(undefined, options))
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

  const getCoins = async () => {
    const q = fsquery(
      collection(fsdb, 'sentcoins'),
      where('playerid', '==', me.id),
      orderBy('isclaimed', 'asc'),
    )

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let certificates = []
      let certificatesEx = []
      let total = 0
      querySnapshot.forEach((doc) => {
        var obj = doc.data()
        console.log(obj)
        obj.id = doc.id
        // obj.isclaimed = obj.isclaimed ? "Yes" : "No";

        // var dif = moment().diff(obj.expiredate, "days");
        // obj.dif = dif;
        if (!obj.isclaimed) {
          total = total + obj.coincount
        }

        // else {
        certificates.push(obj)
        // }
      })
      setTotalCoins(total)

      // if (expired) {
      //   setsentCoins(certificatesEx);
      // } else {
      //   //

      setSentCoins(certificates)
      // }
      // console.log("Current cities in CA: ", cities.join(", "));
    })

    // return unsubscribe
  }

  const modalContent = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', gap: 16, flexDirection: 'column' }}>
          <div className={styles.card}>
            <div className={styles.cardTitle}>WARNING</div>
            <div className={styles.cardDescription}>
              You are about to redeem your coins, this is an action that can not
              be undone. Are you sure you want to claim your coins?
            </div>
          </div>
        </div>
        <div
          style={{
            width: '100%',
            marginTop: 16,
            alignSelf: 'center',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button3d
            style={{ width: '48%' }}
            className={`btn-red`}
            label={'go back'}
            onClick={() => setClaimModalVisible(false)}
          ></Button3d>
          <Button3d
            style={{ width: '48%' }}
            className={`btn-green`}
            label={'Redeem'}
            onClick={() => {
              Claim()
            }}
          ></Button3d>
        </div>
      </div>
    )
  }
  const Claim = async () => {
    const certiRef = doc(fsdb, 'sentcoins', claimId)

    // Set the "capital" field of the city 'DC'
    await updateDoc(certiRef, {
      isclaimed: true,
    })
    setClaimModalVisible(false)
  }
  return (
    <>
      {claimModalVisible && (
        <GameModal
          onClick={() => setClaimModalVisible(false)}
          content={modalContent()}
          top={0}
          left={0}
        ></GameModal>
      )}
      <div>
        <div
          style={{
            backgroundColor: 'white',
            marginBottom: 16,
            padding: 8,
            textAlign: 'center',
            borderRadius: 16,
            position: 'relative',
          }}
        >
          <div
            style={{
              textAlign: 'center',
              fontSize: 24,
              fontWeight: 'bold',
              color: '#FF8A00',
            }}
          >
            {'Total Coins:'}
            {totalCoins}
          </div>
        </div>
        {sentCoins.map(function (certi, key) {
          return (
            <div
              key={key}
              style={{
                display: 'flex',
                flexDirection: 'row',
                position: 'relative',
              }}
              onClick={() => {
                if (!certi.isclaimed) {
                  setClaimId(certi.id)
                  setClaimModalVisible(true)
                }
              }}
            >
              <div
                style={{
                  backgroundColor: 'white',
                  borderRadius: 16,

                  cursor: 'pointer',
                  backgroundRepeat: 'no-repeat',
                  width: '100%',
                  height: '80px',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                  marginBottom: 16,
                }}
              >
                <div
                  style={{
                    width: 80,
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    opacity: certi.isclaimed ? 0.2 : 1,
                  }}
                >
                  <img
                    style={{
                      width: 50,
                      height: 50,

                      zIndex: 1,
                      // rotate: '345deg'
                      opacity: expired ? 0.2 : 1,
                    }}
                    src={images.coins}
                  />
                </div>
                <div
                  className={styles.rightSec}
                  style={{ flex: 1, padding: '8px 16px' }}
                >
                  <div
                    style={{
                      color: certi.isclaimed ? 'gray' : '#953737',
                      textAlign: 'center',
                      fontFamily: 'BebasNeue',
                      lineHeight: 1,
                      maxWidth: '175px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      fontSize: 24,
                      whiteSpace: 'nowrap',
                      marginBottom: 8,
                    }}
                  >
                    {certi.coincount}
                  </div>
                  <div
                    style={{
                      fontWeight: 'bold',
                      color: certi.isclaimed ? 'gray' : '#FF8A00',
                      lineHeight: 1,
                    }}
                  >
                    {certi.code}
                  </div>

                  {!expired ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        lineHeight: 1,
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div
                        className={
                          certi.isclaimed
                            ? styles.disabledText
                            : styles.grayText
                        }
                      >
                        Claimed: {certi.isclaimed ? 'Yes' : 'No'}
                      </div>
                      <div
                        className={
                          certi.isclaimed
                            ? styles.disabledText
                            : styles.grayText
                        }
                      >
                        Issued on: {formatDate(certi.issueddate)}
                      </div>
                    </div>
                  ) : (
                    <div
                      className={
                        expired ? styles.disabledText : styles.grayText
                      }
                      style={{
                        color: expired ? '#953737' : '',
                        fontWeight: expired ? 'bold' : '',
                        lineHeight: 1,
                      }}
                    >
                      {redeemed ? 'Redeemed' : 'Expired'}
                    </div>
                  )}
                  {redeemed && (
                    <img
                      style={{
                        width: '35%',
                        position: 'absolute',
                      }}
                      src={images.RedeemedStamp}
                    />
                  )}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default Coins
