import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './locationCard.module.scss'
import Button3d from 'components/Button3d'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import Button from 'components/Button'
import { useSelector } from 'react-redux'
import {
    getDatabase,
    ref,
    onValue,
    set,
    query,
    orderByChild,
    equalTo,
} from "firebase/database";
import { auth } from 'utils/firebase'
const LocationCard = ({
    back,
    className,
    style,
    label,
    onClick,
    isLoading,
    disabled,
    children,
    image,
    locationRef,
    location,
    status,
    address,
    address2,
    phone,
    schedule,
    btnEnabled,
    locationid,
    dateobject,
    pic,
    userlastsession,
    ismaplocation = false,
    distance = 0,
    timetaken = 0,
    ...others
}) => {
    const db = getDatabase();
    const { me } = useSelector((state) => state.app)
    const lastsession = useRef(null)

    const [reminders, setReminders] = useState([]);

    useEffect(() => {
        getReminders();
    }, []);


    const getReminders = () => {
        const remRef = query(
            ref(db, "reminders/"),
            orderByChild("userid"),
            equalTo(auth.currentUser.uid)
        );
        // const remRef = ref(db, 'reminders/' + auth.currentUser.uid + '/reminders');
        onValue(remRef, (snapshot) => {
            let temparr = [];
            // const data = snapshot.val();
            // console.log(data)

            if (snapshot.exists()) {
                snapshot.forEach((child) => {
                    temparr.push(child.val());
                });
                //  console.log(reminders)
                setReminders(temparr);
            } else {
                // console.log("no reminders............")
                setReminders(temparr);
            }
        });
    };
    const removeFromReminders = async (title, date, id, dt) => {
        set(ref(db, "reminders/" + id + "" + auth.currentUser.uid), {});
    };

    const addToreminders = async (title, date, id, dt, logo) => {
        // alert(JSON.stringify(dt))
        // alert(auth.currentUser.uid)+'/reminders/'+locationid)
        // alert(date)
        // await Notifications.scheduleNotificationAsync({
        //   content: {
        //     title: "Reminder Notice for "+title,
        //     body: date,
        //     // data: { data: 'goes here' },
        //   },
        //   trigger: {date: new Date(Date.now() + 5 * 1000)},
        // });
        // set(ref(db, "users/" + auth.currentUser.uid+'/reminders/'+id), { locationname:title,date:date,locationid:id});

        set(ref(db, "reminders/" + id + "" + auth.currentUser.uid), {
            locationname: title,
            datetime: date,
            locationid: id,
            userid: auth.currentUser.uid,
            token: '0',
            date: dt.date,
            time: dt.time,
            logo: logo
        });
    };

    return <>
        <div style={{ borderRadius: '28px', flex: 1, display: 'flex', backgroundImage: "linear-gradient(#ffe500,#ff7a01)", margin: '16px 8px 16px 16px', }}>



            <div className={styles.locationCard}>

                <div className={styles.locationName} >{location}</div>

                <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                    <div className={styles.leftComponent}>
                        {status &&
                            <div className={styles.capitalLetters} style={{ color: status.color, display: 'flex' }} >
                                <FontAwesomeIcon style={{ marginRight: '5px', color: status.color }} icon={icon({ name: 'circle' })} />
                                <div className='animate__animated animate__fadeIn animate__infinite	infinite'>{status.status}</div>
                            </div>
                        }
                        {/* <div className={styles.capitalLetters} style={{ display: 'flex' }} >
                            <FontAwesomeIcon style={{ marginRight: '5px', color: '#ff7a01' }} icon={icon({ name: 'map-marker' })} />
                            <div>{address} <br />{address2}<br />{JSON.stringify(distance)}</div>
                        </div> */}
                        <div className={styles.capitalLetters} style={{ display: 'flex' }}>
                            <FontAwesomeIcon style={{ marginRight: '5px', color: '#ff7a01' }} icon={icon({ name: 'phone' })} />
                            <div> {phone}</div>
                        </div>
                        <div className={styles.capitalLetters} style={{ color: 'gray', display: 'flex' }}><FontAwesomeIcon style={{ marginRight: '5px', color: '#ff7a01' }} icon={icon({ name: 'calendar' })} />
                            <div>{schedule}</div>
                        </div>
                        <div className={styles.capitalLetters} style={{ color: 'gray', display: 'flex' }}>
                            <FontAwesomeIcon style={{ marginRight: '5px', color: '#ff7a01' }} icon={icon({ name: 'map-marker' })} />
                            <div>  {distance} ({timetaken})</div>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <FontAwesomeIcon style={{ marginRight: '5px', color: '#ff7a01', visibility: 'hidden' }} icon={icon({ name: 'x' })} />
                            <Button onClick={
                                () => {
                                    reminders.findIndex(
                                        (p) => p.locationid == locationid
                                    ) >= 0
                                        ? removeFromReminders(
                                            location,
                                            schedule,
                                            locationid,
                                            dateobject
                                        )
                                        : addToreminders(
                                            location,
                                            schedule,
                                            locationid,
                                            dateobject, pic
                                        );
                                }
                            } className={styles.capitalLetters} style={{ fontSize: '3vh', fontWeight: '500' }} label={reminders.findIndex(
                                (p) => p.locationid == locationid
                            ) >= 0
                                ? "- Remove Reminder"
                                : "+ Add Reminder"}></Button>
                        </div>
                    </div>
                    <div className={styles.rightComponent}>
                        <img style={{ width: '70%', marginBottom: '5px' }} src={pic} />
                        {/* {JSON.stringify(lastsession.current)} */}
                        {status && status.allowjoin && btnEnabled &&
                            <Button3d onClick={() => onClick()} className={`btn-pink`} label={'play'} />}

                        {userlastsession && status && !status.allowjoin && btnEnabled && status.sessionno == userlastsession.sessionno &&
                            <Button3d onClick={() => onClick()} className={`btn-pink`} label={'play'} />}
                    </div>
                </div>
                {/* <div className={styles.capitalLetters} style={{ display: 'flex' }}>
                        <FontAwesomeIcon style={{ marginRight: '5px', color: '#ff7a01' }} icon={icon({ name: 'phone' })} />
                        <div> {phone}</div>
                    </div> */}
                {/* <div className={styles.capitalLetters} style={{ color: 'gray', display: 'flex' }}><FontAwesomeIcon style={{ marginRight: '5px', color: '#ff7a01' }} icon={icon({ name: 'calendar' })} />
                        <div>{schedule}</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <FontAwesomeIcon style={{ marginRight: '5px', color: '#ff7a01', visibility: 'hidden' }} icon={icon({ name: 'x' })} />
                        <Button className={styles.capitalLetters} style={{ fontSize: '3vh', fontWeight: '500' }} label={'+ add reminder'}></Button>
                    </div> */}
            </div>


        </div>
    </>
}

LocationCard.propTypes = {
    label: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.objectOf(PropTypes.object),
    children: PropTypes.node,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
}

LocationCard.defaultProps = {
    label: '',
    className: '',
    style: {},
    onClick: () => { },
    children: null,
    disabled: false,
    isLoading: false,
}

export default LocationCard
