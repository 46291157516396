import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styles from './certificates.module.scss'
import {
  getFirestore,
  collection,
  query as fsquery,
  where,
  onSnapshot,
} from 'firebase/firestore'
import moment from 'moment'
import { colors, images } from 'theme'
import { path } from 'utils/const'

const fsdb = getFirestore()

const Certificates = ({ type, ...others }) => {
  const { me } = useSelector((state) => state.app)
  const history = useHistory()
  const [sentCertificates, setSentCertificates] = useState([])

  useEffect(() => {
    getCertificates()
  }, [])

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
    console.log(new Date(dateString).toLocaleDateString(undefined, options))
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

  const getCertificates = async () => {
    const q = fsquery(
      collection(fsdb, 'sentcertificates'),
      where('playerid', '==', me.id),
    )

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let certificates = []
      let certificatesEx = []
      querySnapshot.forEach((doc) => {
        var obj = doc.data()
        obj.id = doc.id
        obj.isclaimed = obj.isclaimed ? 'Yes' : 'No'
        obj.expired = false
        var dif = moment().diff(obj.expiredate, 'days')
        obj.dif = dif
        // if (dif > 0 || obj.isclaimed == 'Yes') {
        //   certificatesEx.push(obj)
        // } else {
        if (type == 1) {
          if (obj.isclaimed == 'No' && dif <= 0) {
            certificates.push(obj)
          }
        } else if (type == 2) {
          if (obj.isclaimed == 'Yes') {
            certificates.push(obj)
          }
        } else if (type == 3) {
          if (dif > 0 && obj.isclaimed == 'No') {
            obj.expired = true
            certificates.push(obj)
          }
        }

        // }
      })

      //
      setSentCertificates(certificates)

      // console.log("Current cities in CA: ", cities.join(", "));
    })
    console.log(sentCertificates)
  }

  return (
    <>
      <div>
        {sentCertificates.map(function (certi, key) {
          return (
            <div style={{ position: 'relative' }}>
              <div className={styles.shadow}></div>
              <div
                className={styles.coupen}
                key={key}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  position: 'relative',
                }}
                onClick={() => {
                  if (type == 1) {
                    history.push({
                      pathname: path.cuoponPreview,
                      state: {
                        certificate: certi,
                      },
                    })
                  }
                }}
              >
                <div className={styles.coupenleft}>
                  {' '}
                  <img
                    style={{
                      width: '100%',
                      // height: 50,
                      filter: type == 2 || type == 3 ? 'grayscale(100%)' : null,
                      opacity: type == 2 || type == 3 ? 0.8 : 1,
                    }}
                    src={certi.logo ? certi.logo : images.fnfLogo}
                  />
                </div>
                <div className={styles.coupenright}>
                  <div
                    style={{
                      color: type == 2 || type == 3 ? 'gray' : '#953737',
                      textAlign: 'center',
                      fontFamily: 'BebasNeue',
                      lineHeight: 1,
                      // maxWidth: '175px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {certi.certiname}
                    {/* {certi.logo} */}
                  </div>
                  <div
                    style={{
                      fontWeight: 'bold',
                      color: type == 2 || type == 3 ? 'gray' : '#FF8A00',
                      lineHeight: 1,
                      textAlign: 'center',
                    }}
                  >
                    <span
                      style={{
                        color: type == 2 || type == 3 ? 'gray' : 'black',
                      }}
                    >
                      CODE:
                    </span>{' '}
                    {certi.code}
                  </div>
                  <div
                    style={{
                      color: type == 2 || type == 3 ? '#953737' : null,
                      fontWeight: type == 2 || type == 3 ? 'bold' : null,
                    }}
                    className={
                      type == 2 || type == 3
                        ? styles.disabledText
                        : styles.grayText
                    }
                  >
                    {type == 2
                      ? 'Redeemed'
                      : type == 3
                      ? 'Expired'
                      : 'Expires:'}
                    {type == 2 || type == 3
                      ? null
                      : formatDate(certi.expiredate)}
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default Certificates
