import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/Modal'
import FontIcon from 'components/FontIcon'
import Button from 'components/Button'
import { useHistory } from 'react-router-dom'
import styles from './WinPattern.module.scss'
import { path } from 'utils/const'
import { images } from 'assets'
import CardButton from 'pageComponents/CardButton'
import LCardButton from 'pageComponents/LCardButton'

const WinPattern = ({
  tiles,
  isWinPattern, back, category, loteria
}) => {
  const wordBingo = ["B", "I", "N", "G", "O"]
  //Game Ball Images
  const gameBallImages = [
    images.blueBall,
    images.redBall,
    images.greyBall,
    images.greenBall,
    images.yellowBall,
  ];
  const gameBallImagesL = [
    images.one,
    images.two,
    images.three,
    images.four,
    images.five,
  ];

  // alert(JSON.stringify(groupedData))
  return <div  >
    <div className={styles.wrapper} >
      <div style={{ display: 'flex', flexDirection: 'column', backgroundSize: 'cover', flex: 1, backgroundColor: '#000000bf', justifyContent: 'center', alignItems: 'center' }}>


        <div class="animate__animated animate__bounceIn" style={{ width: '94%', backgroundColor: '#ecedef', borderRadius: 8, }}>
          <div className={styles.gridContainer} style={{ gridGap: 2, padding: 4 }} >
            {
              wordBingo.map(function (item, i) {

                return <div key={'b' + i} style={{ fontSize: '4vh', fontFamily: 'GamerStation', aspectRatio: '1', backgroundSize: 'cover', backgroundImage: `url(${category == 4 ? gameBallImagesL[i] : gameBallImages[i]})`, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }} >
                  {category != 4 && item}
                </div>



              })
            }

          </div>
          <div className={styles.gridContainer} style={{ gridGap: 2, padding: 4 }}>


            {
              tiles.map(function (item, i) {

                return <Button type="button" className={styles.gridItem}>
                  {/* {item.no} */}



                  {
                    category == 4 ? <LCardButton img={
                      loteria.find(
                        (item_) => item_.id == item.no
                      )?.url
                    } index={i} back={back} item={item} />
                      :
                      <CardButton isWinPattern={isWinPattern} index={i} back={back} item={item} />


                  }




                </Button>




              })
            }

          </div>

        </div>

      </div>
    </div>
  </div>
}

// SentResetPassword.propTypes = {
//   email: PropTypes.string,
//   isOpen: PropTypes.bool,
//   toggle: PropTypes.func,
//   onSubmit: PropTypes.func,
// }

// SentResetPassword.defaultProps = {
//   email: '',
//   isOpen: false,
//   toggle: () => {},
//   onSubmit: () => {},
// }

export default WinPattern
